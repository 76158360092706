import React from "react";
import InstagramEmbed from "react-instagram-embed";
const InstagramWidget = () => {
  return (
    <div>
      <InstagramEmbed
        url="https://www.instagram.com/p/CSd8OC3h6pl/"
        clientAccessToken="2827657450878699|9294935e025689c7245f7cf458f3e4ef"
        maxWidth={320}
        hideCaption={false}
        containerTagName="div"
        protocol=""
        injectScript
        onLoading={() => {}}
        onSuccess={() => {}}
        onAfterRender={() => {}}
        onFailure={() => {}}
      />
    </div>
  );
};

export default InstagramWidget;
