import { CheckOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import SpecsBanner from "../SpecsBanner";
import TopFooter from "../TopFooter";

const About = () => {
  const router = useHistory();
  const { TabPane } = Tabs;
  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-2xl text-2xl mb-4 font-medium text-gray-900">
              We are an organisation that is involved in development, relief and
              advocacy activities for a better world.
            </h1>
            <p className="mb-8 leading-relaxed">
              These are a few of our most recent programs. Every year, we carry
              out more than 100 programs across the country and internationally,
              involving people of every age, interest and need.
            </p>
            <Button
              type="primary"
              size="large"
              onClick={() => router.push("/home/projects")}
            >
              Donate
            </Button>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded-xl shadow-2xl"
              alt="hero"
              src={require("../../../assets/images/water.jpg").default}
            />
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-10 mx-auto">
          <div className="flex flex-col text-center w-full mb-5">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-yellow-600">
              We love helping people
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Our organisation is a medium Australian NGO and delivers
              humanitarian initiatives on a non-discriminatory and
              non-denominational basis in 22 countries. Placed at the front line
              of the Australian non-profit industry, Australian Relief
              Organisation Limited is
            </p>
          </div>
          <div className="flex flex-wrap">
            <div className="xl:w-1/3 lg:w-1/3 md:w-full px-8  border-l-2 border-gray-200 border-opacity-60">
              <p className="leading-relaxed text-base mb-4">
                Registered as a Public Company by ASIC (Australian Securities
                and Investments Commission),
              </p>
            </div>
            <div className="xl:w-1/3 lg:w-1/3 md:w-full px-8 border-l-2 border-gray-200 border-opacity-60">
              <p className="leading-relaxed text-base mb-4">
                Certified as a Charity and a Public Benevolent Institution by
                ACNC (Australian Charities and Not-for-Profits Commission) and
              </p>
            </div>
            <div className="xl:w-1/3 lg:w-1/3 md:w-full px-8 border-l-2 border-gray-200 border-opacity-60">
              <p className="leading-relaxed text-base mb-4">
                Endorsed as a DGR (Deductible Gift Recipient) by ATO (Australian
                Taxation Office) to provide tax deductible donation receipts.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="container mb-10">
        <Tabs defaultActiveKey="1">
          <TabPane tab={<h2 className="text-lg">Local Activities</h2>} key="1">
            <section className="text-blueGray-700 ">
              <div className="container flex flex-col items-center px-5 py-2 mx-auto md:flex-row lg:flex-row">
                <div className="flex flex-col items-start w-full pt-0 mb-16 text-left lg:flex-grow md:w-3/4 xl:mr-20 md:pr-24 md:mb-0 ">
                  <h1 className="mb-8 text-2xl font-bold tracking-tighter text-left text-black lg:text-2xl title-font">
                    What you do matters
                  </h1>

                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Social Inclusion Services Aged care and hospital visits by
                    volunteers
                  </p>
                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Projects for People with Disabilities Vounteer visits and
                    fundraising initiatives
                  </p>
                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Refugee & Asylum Seeker Support Assisting the newcomers
                    through social integration programs
                  </p>
                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Homeless Support Assisting the homeless via community
                    kitchen programs
                  </p>
                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Social Responsibility Program Involving college and uni
                    students with charitable programs
                  </p>
                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Clothing Donations Collection of apparel for clothing
                    appeals and charity shop
                  </p>
                </div>
                <div className="w-full lg:w-5/6 lg:max-w-lg md:w-1/2">
                  <img
                    className="object-cover object-center rounded-xl shadow-2xl "
                    alt="hero"
                    src={require("../../../assets/images/about.jpg").default}
                  />
                </div>
              </div>
            </section>
          </TabPane>
          <TabPane
            tab={<h2 className="text-lg">International Activities</h2>}
            key="2"
          >
            <section className="text-blueGray-700 ">
              <div className="container flex flex-col items-center px-5 py-2 mx-auto md:flex-row lg:flex-row">
                <div className="flex flex-col items-start w-full pt-0 mb-16 text-left lg:flex-grow md:w-3/4 xl:mr-20 md:pr-24 md:mb-0 ">
                  <h1 className="mb-8 text-2xl font-bold tracking-tighter text-left text-black lg:text-2xl title-font">
                    Turn off the tap
                  </h1>

                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Water Wells Establishment of water wells in Africa and Asia
                    Pacific
                  </p>
                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Orphanages Construction and refurbishment of orphanages in
                    Africa and Asia Pacific
                  </p>
                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Health Sponsorship of cataract surgeries in Africa
                  </p>
                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Sponsorships & Alms Financial support to orphans worldwide
                  </p>
                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Emergency Relief Financial aid and clothing donations to the
                    needy worldwide
                  </p>
                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Meat Appeal Meat donations to the needy worldwide
                  </p>
                  <p className="flex items-center mb-2 text-blueGray-600 text-base">
                    <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                      <CheckOutlined />
                    </span>
                    Dinner & Food Pack Appeal Dinner and food pack donations to
                    the needy worldwide
                  </p>
                </div>
                <div className="w-full lg:w-5/6 lg:max-w-lg md:w-1/2">
                  <img
                    className="object-cover object-center rounded-xl shadow-2xl "
                    alt="hero"
                    src={
                      require("../../../assets/images/international.jpg")
                        .default
                    }
                  />
                </div>
              </div>
            </section>
          </TabPane>
        </Tabs>
        <section className="text-blueGray-700 ">
          <div className="container flex flex-col items-center px-5 py-8 mx-auto">
            <div className="flex flex-col w-full mb-12 text-left ">
              <div className="w-full mx-auto lg:w-3/4">
                <h2 className="mx-auto mb-6 text-xl font-semibold mt-20 text-black title-font">
                  By July 2016, our organization was nationally recognized and
                  received an “Outstanding Achievement Award” at the Australian
                  Business Awards.
                </h2>
                <p className="mx-auto mb-4 text-base font-medium leading-relaxed text-blueGray-700 ">
                  We are grateful to our supporters, as their trust in our
                  organization and funding paved the way for significant
                  humanitarian achievements. Through our international
                  development, public health, human aid, and emergency relief
                  programs; we
                </p>
                <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                  <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                    <CheckOutlined />
                  </span>
                  Established 109 water wells and secured clean drinkable water
                  to 72,130 locals in Tanzania, Cambodia, and Malawi,
                </p>
                <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                  <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                    <CheckOutlined />
                  </span>
                  Sponsored surgical operations in Africa and restored sight to
                  609 cataract patients,
                </p>
                <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                  <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                    <CheckOutlined />
                  </span>
                  (with local partnership) Established an orphanage in Cambodia,
                  which hosts 60 youth with free boarding and meals,
                </p>

                <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                  <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                    <CheckOutlined />
                  </span>
                  Undertook 3 orphanage refurbishment projects and improved
                  living conditions of 240 youth in Malawi and Cambodia,
                </p>
                <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                  <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                    <CheckOutlined />
                  </span>
                  Provided regular financial support to 160 orphans,
                </p>
                <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                  <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                    <CheckOutlined />
                  </span>
                  Provided food support to 63,428 people (est) across 6
                  countries in Asia Pacific by distributing iftars and food
                  packs through annual Ramadan Appeal program,
                </p>
                <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                  <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                    <CheckOutlined />
                  </span>
                  Provided meat support to 197,610 families (est) across 12
                  countries by distributing meat packs through annual Qurban
                  Appeal program,
                </p>
                <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                  <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                    <CheckOutlined />
                  </span>
                  Delivered clothing and financial aid to 19,000 people in 6
                  countries.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <SpecsBanner />
      <br />
      <TopFooter />
      <br />
    </div>
  );
};

export default About;
