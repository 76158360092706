import {
  ArrowRightOutlined,
  MinusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  Radio,
  Select,
  Space,
  message,
  notification,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { GlobalVariables } from "../../resources/GlobalVariables";
import { AdminService } from "../../services/AdminService";

const DonationsForm = ({ activeId = null, isModal = false }) => {
  const [initialRender, setInitialRender] = useState(true);
  //const [projects, setProjects] = useState([]);
  const [activeProject, setActiveProject] = useState(null);
  const [form] = Form.useForm();
  const { lg } = useBreakpoint();
  const [qty, setQty] = useState(1);
  const [qtyTotal, setQtyTotal] = useState(0);
  const [interval, setInterval] = useState(1);
  const [times, setTimes] = useState(1);
  const [intervalPanel, setIntervalPanel] = useState(false);
  const [planModal, setPlanModal] = useState(false);
  const [plan, setPlan] = useState([]);
  const [activeValue, setActiveValue] = useState(20);
  const router = useHistory();
  const {
    setDonations,
    setCheckout,
    setCoDrawerOpen,
    donations,
    setDonatModal,
    checkout,
    setSelectedProject,
  } = useContext(GlobalContext);

  useEffect(() => {
    //  console.log("active id:", activeId);

    AdminService.getProjectByIdForDonationsForm(activeId).then((data) => {
      //    console.log(data.data.project);
      // setProjects(data.data);
      // if (activeId !== null) {
      setActiveProject(data.data.project);
      form.setFieldsValue({ projectId: activeId });
      form.setFieldsValue({ interval: 1 });
      //   }
    });
  }, [activeId, form]);
  // useEffect(() => {
  //   if (projects.length > 0) {
  //     setActiveProject(
  //       projects[projects.findIndex((d) => d.project.id === activeId)].project
  //     );
  //   }
  // }, [activeId, projects]);

  // const projectSelected = (p) => {
  //   const index = projects.findIndex((pr) => pr.project.id === p);
  //   setActiveProject(projects[index].project);
  //   setSelectedProject(projects[index].project);
  // };

  const intervalChange = (a) => {
    setInterval(a.target.value);
    a.target.value !== 1 && times === 1 && setTimes(2);
    if (a.target.value === 1) {
      setTimes(1);
      form.setFieldsValue({ times: 2 });
    }
    setIntervalPanel(a.target.value === 1 ? false : true);
  };

  const onFinish = (values) => {
    if (activeProject.pType === 1) {
      values = {
        ...values,
        total: form.getFieldsValue().qurbans.length * activeProject.qurbanPrice,
        pType: 1,
        project: activeProject.name,
        projectId: activeProject.id,
        dparts: null,
      };
      setDonations([...donations, values]);
      setCheckout(
        checkout +
          form.getFieldsValue().qurbans.length * activeProject.qurbanPrice
      );
      onReset();
    }

    if (activeProject.pType === 2) {
      if (qtyTotal < 2) {
        notification.error({ message: "Amount cannot be less than $2" });
        return;
      }
      values = {
        ...values,
        total: qtyTotal,
        pType: 2,
        project: activeProject.name,
        projectId: activeProject.id,
        dparts: null,
      };
      setDonations([...donations, values]);
      setCheckout(checkout + qtyTotal);
      onReset();
    }

    if (activeProject.pType === 3) {
      if (values.ppValue === null && values.paValue === null) {
        notification.error({ message: "Value can not be null" });
        return;
      }
      const toplam = values.ppValue === null ? values.paValue : values.ppValue;
      values = {
        ...values,
        project: activeProject.name,
        projectId: activeProject.id,
        total: toplam,
        pType: 3,
        dparts: null,
      };
      setDonations([...donations, values]);
      setCheckout(checkout + toplam);
      onReset();
    }

    if (activeProject.pType === 4) {
      const temp = [];
      var today = moment();
      for (let index = 0; index < form.getFieldsValue().times; index++) {
        var per = getPeriod();
        // @ts-ignore
        var dateuse = moment(today).add(index, per);
        temp.push(moment(dateuse).format());
      }
      values = {
        ...values,
        projectId: activeProject.id,
        pType: 4,
        project: activeProject.name,
        dparts: temp,
        total:
          values.interval === 1 ? values.value : values.value * values.times,
        times: values.times,
      };
      setDonations([...donations, values]);
      setCheckout(checkout + values.total);
    }

    message.success("Donation added your donation box");
    setCoDrawerOpen(true);
    setDonatModal(false);
    // setCoTooltip(true);
    // setTimeout(() => {
    //   setCoTooltip(false);
    // }, 5000);
    onReset();
  };
  const onReset = () => {
    form.resetFields();
    setQty(1);
    setQtyTotal(0);

    if (activeId !== null) {
      form.setFieldsValue({ projectId: activeId });
      form.setFieldsValue({ interval: 1 });
      setActiveValue(20);
    }
  };

  const changeQty = (i) => {
    i ? setQty(qty + 1) : setQty(qty - 1);
  };
  const yuzdeChange = () => {
    form.setFieldsValue({ paValue: null });
  };
  const amountChange = () => {
    form.setFieldsValue({ ppValue: null });
  };
  const qtyInputChange = (a) => {
    var tot = 0;
    activeProject.quantityItems.forEach((q) => {
      if (form.getFieldsValue()["items"][q.id] !== undefined) {
        tot = tot + form.getFieldsValue()["items"][q.id] * q.price;
      }
    });

    setQtyTotal(tot);
  };
  const getPeriod = () => {
    switch (parseInt(form.getFieldsValue().interval)) {
      case 2:
        return "days";
      case 3:
        return "weeks";
      case 4:
        return "months";
      case 5:
        return "years";
      default:
        return "one off";
    }
  };
  const timesChanged = (v) => {
    setTimes(v);
  };
  const goModal = () => {
    const temp = [];
    var today = moment();
    for (let index = 0; index < form.getFieldsValue().times; index++) {
      var per = getPeriod();
      // @ts-ignore
      var dateuse = moment(today).add(index, per);
      temp.push(dateuse);
    }
    setPlan(temp);
    setPlanModal(true);
  };
  const priceButtons = [
    { key: 20, value: "$20" },
    { key: 50, value: "$50" },
    { key: 100, value: "$100" },
    { key: 200, value: "$200" },
    { key: 500, value: "$500" },
    { key: 0, value: "Your Value" },
  ];
  const valueChanged = (val) => {
    setActiveValue(val);
    form.setFieldsValue({ value: val });
  };

  const valChange = (a) => {
    form.setFieldsValue({ value: parseInt(a.target.value) });
  };
  const prim = (val) => {
    return val === activeValue ? "primary" : "default";
  };
  return (
    <div>
      <Modal
        visible={planModal}
        onCancel={() => setPlanModal(false)}
        onOk={() => setPlanModal(false)}
        width="350px"
      >
        {activeProject && activeProject.pType === 4 && (
          <Descriptions
            title={activeProject.name + " donation plan"}
            bordered
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            size="small"
          >
            {plan.map((p) => (
              <Descriptions.Item
                key={moment(p).format("DD-MM-YYYY dddd")}
                label={moment(p).format("DD-MM-YYYY dddd")}
              >
                <span>${form.getFieldsValue().value}</span>
              </Descriptions.Item>
            ))}
            <Descriptions.Item
              label={<b>Total Value</b>}
              style={{ border: "1px solid #ddd" }}
            >
              <b style={{ whiteSpace: "nowrap" }}>
                ${plan.length * form.getFieldsValue().value}
              </b>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Form
          form={form}
          onFinish={onFinish}
          onReset={onReset}
          initialValues={{
            qurbans: [{}],
            interval: 1,
            ppValue: null,
            paValue: null,
            times: 2,
            value: 20,
            total: 0,
          }}
          style={{ maxWidth: "430px" }}
        >
          {/* {activeId !== null && (
          <Form.Item>
            <Divider orientation="left" style={{ color: "#D26D24" }}>
              {name !== null ? "Donate " + name : "Donate to this project"}
            </Divider>
          </Form.Item>
        )} */}
          {/* <Form.Item
            hidden={activeId !== null}
            {...GlobalVariables.formLayout}
            label="Choose project"
            name="projectId"
            rules={[{ required: true, message: "Please choose project" }]}
          >
            <Select
              onChange={projectSelected}
              size="large"
              placeholder="Please choose project"
            >
              {projects.map((p) => (
                <Select.Option key={p.key} value={p.project.id}>
                  {p.project.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}

          <Form.Item>
            {activeProject && (
              <Space
                direction={lg ? "horizontal" : "vertical"}
                style={{
                  //marginLeft: "15px",
                  marginTop: "15px",
                  border: "1px solid #ddd",
                  backgroundColor: "white",
                  padding: "5px 15px",
                  borderRadius: "3px",
                }}
                size="small"
              >
                <span style={{ color: "#D26D24", fontSize: "16px" }}>
                  Donate to
                </span>
                <span style={{ color: "#D26D24", fontSize: "16px" }}>
                  {activeProject.name}
                </span>
              </Space>
            )}
          </Form.Item>

          {activeProject && activeProject.pType === 1 && (
            <Form.List name="qurbans">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space
                      key={key}
                      direction="vertical"
                      size="small"
                      style={{
                        borderBottom: "1px solid #ccc",
                        marginBottom: "15px",
                        width: "100%",
                      }}
                    >
                      <Space
                        key={key}
                        style={{ display: "flex" }}
                        align="baseline"
                      >
                        <Form.Item>
                          <ArrowRightOutlined />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "onbehalf"]}
                          fieldKey={[fieldKey, "onbehalf"]}
                          rules={[
                            { required: true, message: "Missing  fullname" },
                          ]}
                        >
                          <Input placeholder="On Behalf" autoFocus />
                        </Form.Item>
                        <MinusCircleTwoTone
                          twoToneColor="#ff0000"
                          onClick={() => {
                            remove(name);
                            changeQty(false);
                          }}
                        />
                      </Space>
                      <Form.Item
                        {...restField}
                        name={[name, "type"]}
                        fieldKey={[fieldKey, "type"]}
                        rules={[
                          { required: true, message: "Missing  options" },
                        ]}
                      >
                        <Radio.Group
                          options={GlobalVariables.qurbanOptions}
                          optionType="button"
                          buttonStyle="solid"
                          style={{ marginTop: "-10px" }}
                          size={lg ? "middle" : "small"}
                        />
                      </Form.Item>
                    </Space>
                  ))}
                  <Form.Item>
                    <Space
                      direction="vertical"
                      size="small"
                      style={{ width: "100%" }}
                    >
                      <span>
                        Total: {fields.length} x ${activeProject.qurbanPrice} =
                        <b> ${fields.length * activeProject.qurbanPrice}</b>
                      </span>
                      <Button
                        type="primary"
                        ghost
                        onClick={() => {
                          add();
                          changeQty(true);
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Another Qurban
                      </Button>
                    </Space>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
          {activeProject && activeProject.pType === 3 && (
            <>
              <Form.Item label="Full Price" {...GlobalVariables.formLayout}>
                <Text>
                  <b>
                    ${activeProject.pTotalPrice} per project. Select your
                    portion or type your amount
                  </b>
                </Text>
              </Form.Item>

              <Form.Item
                label="Select portion"
                {...GlobalVariables.formLayout}
                name="ppValue"
              >
                <Select
                  size="large"
                  onChange={yuzdeChange}
                  placeholder="Please choose your percentage"
                >
                  <Select.Option
                    key="1"
                    value={activeProject.pTotalPrice * 0.1}
                  >
                    Donate 10% (<b>${activeProject.pTotalPrice * 0.1}</b>)
                  </Select.Option>
                  <Select.Option
                    key="2"
                    value={activeProject.pTotalPrice * 0.25}
                  >
                    Donate 25% (<b>${activeProject.pTotalPrice * 0.25}</b>)
                  </Select.Option>
                  <Select.Option
                    key="3"
                    value={activeProject.pTotalPrice * 0.5}
                  >
                    Donate 50% (<b>${activeProject.pTotalPrice * 0.5}</b>)
                  </Select.Option>
                  <Select.Option
                    key="4"
                    value={activeProject.pTotalPrice * 0.75}
                  >
                    Donate 75% (<b>${activeProject.pTotalPrice * 0.75}</b>)
                  </Select.Option>
                  <Select.Option key="5" value={activeProject.pTotalPrice}>
                    Donate 100% (<b>${activeProject.pTotalPrice}</b>)
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol="15"
                label={
                  <span
                    style={{
                      whiteSpace: "pre-wrap",
                      lineHeight: "15px",
                    }}
                  >
                    or type your value
                  </span>
                }
                name="paValue"
                {...GlobalVariables.formLayout}
              >
                <InputNumber
                  inputMode="decimal"
                  size="large"
                  formatter={(value) => `$${value}`}
                  onChange={amountChange}
                  min={2}
                  max={activeProject.pTotalPrice * 10}
                />
              </Form.Item>
            </>
          )}
          {activeProject && activeProject.pType === 2 && (
            <>
              <Form.Item>
                <b>Select donations and quantities</b>
                <br />
                {activeProject.id === 13 && (
                  <p>
                    While it is important to protect and improve the lives of
                    refugees every single day, international days like World
                    Refugee Day help to focus global attention on the plight of
                    those fleeing conflict or persecution. This fundraising
                    activity is organised by Advocates for Dignity (AFD), Sirius
                    College, Selimiye Foundation, and the Australian Relief
                    Organisation (ARO) to help desperate refugees in Greece who
                    have no job or financial aid to assist them.
                  </p>
                )}
                {activeProject.id === 47 && (
                  <p>
                    Our gift cards offer essential support for various needs
                    like school books, stationery, uniforms, groceries, and
                    clothing. Your generosity goes above and beyond, providing
                    crucial rent assistance to establish a stable living
                    environment for those in need. Thank you for making a
                    difference with your thoughtful support.
                  </p>
                )}
              </Form.Item>
              <Form.Item>
                {activeProject.quantityItems.map((q, index) => (
                  <Form.Item
                    name={["items", q.id + ""]}
                    key={index}
                    label={q.name + " ($" + (q.price > 1 ? q.price : "") + ")"}
                    {...GlobalVariables.formLayoutLargeTitle}
                  >
                    <InputNumber
                      inputMode="numeric"
                      min={0}
                      //   max={1000}
                      size="large"
                      onChange={qtyInputChange}
                    />
                  </Form.Item>
                ))}
              </Form.Item>
              {/* <Form.Item>
                <Button
                  type="primary"
                  ghost
                  onClick={() => router.push("/home/projects/29")}
                >
                  Click to Automate your sadaqa and zakat
                </Button>
              </Form.Item> */}
            </>
          )}
          {activeProject && activeProject.pType === 4 && (
            <>
              <Form.Item>
                <Form.Item name="interval">
                  <Radio.Group
                    onChange={intervalChange}
                    value={interval}
                    buttonStyle="outline"
                    size="large"
                  >
                    {activeProject.intervals.map((interval) => (
                      <Radio.Button
                        className="interval-button"
                        key={interval.id}
                        value={interval.id}
                      >
                        {interval.name}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
                {form.getFieldsValue().interval !== 1 && intervalPanel && (
                  <>
                    <span>
                      Recurring donations will be charged in scheduled{" "}
                      {getPeriod()}, starting from the first date of payment.
                      You may end recurring donations anytime.
                    </span>
                    <br />
                    <span style={{ color: "#D26D24" }}>
                      How many {getPeriod()} do you do with time donation :
                    </span>
                  </>
                )}
              </Form.Item>
              {form.getFieldsValue().interval !== 1 && intervalPanel && (
                <Form.Item>
                  <Form.Item name="times" style={{ display: "inline-block" }}>
                    <InputNumber
                      inputMode="numeric"
                      size="large"
                      min={2}
                      max={1000}
                      onChange={timesChanged}
                    />
                  </Form.Item>
                  &nbsp; {getPeriod()}.{" "}
                  <Button
                    size="large"
                    onClick={goModal}
                    style={{ border: "1.5px dashed #DEAD7F" }}
                  >
                    Check Plan
                  </Button>
                </Form.Item>
              )}

              <Form.Item
                name="value"
                rules={[
                  { required: true, message: "Value required" },
                  { type: "number", min: 2, max: 10000 },
                ]}
              >
                <List
                  grid={{
                    gutter: 3,
                    xs: 3,
                    sm: 3,
                    md: 3,
                    lg: 3,
                    xl: 3,
                    xxl: 3,
                  }}
                  dataSource={priceButtons}
                  renderItem={(item) => (
                    <List.Item>
                      <Button
                        className="price-button"
                        block
                        type={prim(item.key)}
                        onClick={() => valueChanged(item.key)}
                        size="large"
                        style={{ borderColor: item.key === 0 ? "#DEAD7F" : "" }}
                      >
                        {item.value}
                      </Button>
                    </List.Item>
                  )}
                />
                {activeValue === 0 && (
                  <Input
                    inputMode="decimal"
                    placeholder="Type your value"
                    prefix="$"
                    size="large"
                    style={{ maxWidth: "250px" }}
                    onChange={(a) => valChange(a)}
                  />
                )}
              </Form.Item>
            </>
          )}
          <Form.Item>
            {activeProject && (
              <Space wrap>
                <Button type="primary" htmlType="submit" disabled={qty === 0}>
                  {activeProject.pType === 1
                    ? "Donate " + qty + " qurban"
                    : activeProject.pType === 2
                    ? "Donate $" + qtyTotal
                    : "Donate"}
                </Button>
                <Button htmlType="reset">Clear</Button>
                {donations.length > 0 && !isModal && (
                  <Button
                    type="primary"
                    danger
                    onClick={() => router.push("/home/checkout")}
                  >
                    Checkout
                  </Button>
                )}
              </Space>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default DonationsForm;
