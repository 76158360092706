import Homepage from "../components/home/Homepage";
import {
  ClusterOutlined,
  GiftOutlined,
  HomeOutlined,
  IdcardOutlined,
  MailOutlined,
  MenuOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import { Layout, Row, Col, Button, Drawer, Menu, Space, Tooltip } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import AroFooter from "../components/home/AroFooter";
import { GlobalContext } from "../context/GlobalContext";
import CoDrawer from "../components/home/CoDrawer";
import CheckOutPage from "../components/donation/CheckOutPage";
import ProjectsPage from "../components/home/ProjectsPage";
import ProjectDetails from "../components/home/ProjectDetails";
import ContactPage from "../components/home/ContactPage";
import ThankYou from "../components/home/ThankYou";
import About from "../components/home/pages/About";
import Awards from "../components/home/pages/Awards";
import Volunteers from "../components/home/pages/Volunteers";
import ClothingAppeal from "../components/home/pages/ClothingApeeal";
import MyPage from "../components/home/myPage/MyPage";
import DonateIcon from "../resources/DonateIcon";
import SellForCharity from "../components/home/pages/SellForCharity";

const HomeLayout = () => {
  const { Header, Content } = Layout;
  const location = useLocation();
  const { lg } = useBreakpoint();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [actives, setActives] = useState([location.pathname.split("/").pop()]);
  const { checkout, coTooltip, setCoDrawerOpen } = useContext(GlobalContext);
  const router = useHistory();
  const { SubMenu } = Menu;
  const menuClick = (e) => {
    router.push("/home/" + e.key);
    setActives([e.key]);
    setIsCollapsed(false);
  };
  const MainMenu = () => (
    <Menu
      className="main-menum"
      mode={lg ? "horizontal" : "inline"}
      defaultSelectedKeys={actives}
      onClick={menuClick}
      theme={lg ? "dark" : "light"}
    >
      <Menu.Item key="homepage" icon={<HomeOutlined />}>
        Home
      </Menu.Item>
      <Menu.Item key="projects" icon={!lg && <ClusterOutlined />}>
        Projects
      </Menu.Item>

      {/* <Menu.Item key="donate">Donate</Menu.Item> */}
      <SubMenu title="Appeals" key="sub-2" icon={!lg && <ClusterOutlined />}>
        <Menu.Item key="clothing">Clothing Appeal</Menu.Item>
        <Menu.Item key="sellforcharity">Sell For Charity</Menu.Item>
      </SubMenu>
      <SubMenu title="About" key="sub-1" icon={!lg && <IdcardOutlined />}>
        <Menu.Item key="about">About Us</Menu.Item>
        <Menu.Item key="awards">Awards</Menu.Item>
        <Menu.Item key="volunteers">Volunteers</Menu.Item>
      </SubMenu>
      <Menu.Item key="contact" icon={!lg && <MailOutlined />}>
        Contact
      </Menu.Item>
      <Menu.Item key="mypage" icon={!lg && <GiftOutlined />}>
        My Donations
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout className="layout">
      <Header
        className="header-class"
        style={{
          position: lg ? "fixed" : "unset",
          zIndex: 111,
          width: "100%",
          //  backgroundColor: "#000F1D",
          borderBottom: "1px solid #022A51",
          //   boxShadow: "1px 1px 10px #ddd",
        }}
      >
        <div className="container">
          <Row justify="space-between" align="middle">
            <Col span={12}>
              <div
                className="logo"
                onClick={() => router.push("/")}
                style={{ cursor: "pointer" }}
              />
              {lg && (
                <div
                  className="logo-detay"
                  onClick={() => router.push("/")}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <Space>
                {lg && (
                  <>
                    <Button
                      type="primary"
                      size="large"
                      icon={<RocketOutlined />}
                      onClick={() =>
                        router.push("/home/projects/2", { detay: false })
                      }
                    >
                      Quick Donate
                    </Button>
                    {/* <Button
                      type="primary"
                      size="large"
                      icon={<ScheduleOutlined />}
                      onClick={() => router.push("/home/projects")}
                    >
                      Donate Regularly
                    </Button> */}
                  </>
                )}
                <Tooltip
                  title="Donation Box Updated"
                  visible={coTooltip}
                  placement="bottomLeft"
                  color="lime"
                >
                  <Button
                    size="large"
                    type="default"
                    icon={
                      <DonateIcon
                        width="22"
                        height="22"
                        fill="#D77927"
                        style={{
                          display: "inline",
                          marginRight: "5px",
                          marginBottom: "3px",
                        }}
                      />
                    }
                    onClick={() => setCoDrawerOpen(true)}
                  >
                    $ {checkout}
                  </Button>
                </Tooltip>
              </Space>

              {!lg && (
                <Button
                  style={{ marginLeft: "5px" }}
                  icon={<MenuOutlined />}
                  size="large"
                  onClick={() => setIsCollapsed(true)}
                />
              )}
            </Col>
          </Row>
        </div>
        {lg && (
          <Row>
            <Col span={24}>
              <MainMenu />
            </Col>
          </Row>
        )}
      </Header>
      <Content style={{ padding: "0", marginTop: lg ? "115px" : "unset" }}>
        <Drawer
          title={
            <img
              src={require("../assets/images/logo.png").default}
              width="150"
              alt="logo"
            />
          }
          visible={isCollapsed}
          placement="left"
          closable={true}
          onClose={() => setIsCollapsed(false)}
        >
          <MainMenu />
        </Drawer>
        <div className="site-layout-content">
          <Switch>
            <Route path="/home" exact>
              <Redirect to="/home/homepage" />
            </Route>
            <Route path="/home/homepage" component={Homepage} />
            <Route path="/home/about" component={About} />
            <Route path="/home/volunteers" component={Volunteers} />
            <Route path="/home/awards" component={Awards} />
            <Route path="/home/completed" component={ThankYou} />
            <Route path="/home/projects/:id" component={ProjectDetails} />
            <Route path="/home/projects" component={ProjectsPage} />
            <Route path="/home/clothing" component={ClothingAppeal} />
            <Route path="/home/sellforcharity" component={SellForCharity} />
            <Route path="/home/donate" component={ProjectsPage} />
            <Route path="/home/checkout" component={CheckOutPage} />
            <Route path="/home/contact" component={ContactPage} />
            <Route path="/home/mypage" component={MyPage} />
            <Route>
              <Redirect to="/home" />
            </Route>
          </Switch>
        </div>
        <CoDrawer />
      </Content>
      <div style={{ textAlign: "center" }}>
        <AroFooter />
      </div>
    </Layout>
  );
};

export default HomeLayout;
