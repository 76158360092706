import { Row, Col } from "antd";
import React from "react";
import ContactForm from "./ContactForm";
import SpecsBanner from "./SpecsBanner";
import TopFooter from "./TopFooter";

const ContactPage = () => {
  return (
    <div className="container mt-8">
      <img
        src={require("../../assets/images/contact-top.jpg").default}
        width="100%"
        alt="contact"
        style={{
          borderRadius: "30px",
          boxShadow: "0 10px 30px 0  #ddd",
        }}
      />
      <br />
      <Row gutter={[36, 36]} align="top">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <br />
          <h1
            className="font-merri fw-900"
            style={{
              fontSize: "30px",
              textAlign: "center",
              color: "#d46b08",
            }}
          >
            Send us a message
          </h1>
          <ContactForm />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <br />
          <h1
            className="font-merri fw-900"
            style={{
              fontSize: "30px",
              textAlign: "center",
              color: "#d46b08",
            }}
          >
            Contact Us
          </h1>
          <h3>
            <b>Head Office</b>
          </h3>
          27 Queen St, Auburn NSW 2144
          <br />
          <br />
          <h3>
            <b>Melbourne Branch</b>
          </h3>
          22-24 Lismore St, Dallas VIC 3047 <br />
          <br />
          <h3>
            <b>Operating Hours </b>
          </h3>
          Monday to Friday 9am to 5pm <br />
          <br />
          <h3>
            <b>Phone</b>
          </h3>
          <h3>02 8065 8469 - Sydney</h3>
          <h3>03 9309 6168 - Melbourne</h3> <br />
          <h3>
            <b>Email</b>
          </h3>
          <h3>info@aro.org.au</h3>
        </Col>
      </Row>
      <br />
      <br />
      <br />

      <SpecsBanner />
      <br />
      <TopFooter />
      <br />
      <br />
    </div>
  );
};

export default ContactPage;
