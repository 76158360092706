import { FileSearchOutlined, HeartOutlined } from "@ant-design/icons";
import { Button, Card, Col, Image, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Modal from "antd/lib/modal/Modal";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import DonationsForm from "../donation/DonationsForm";

const ProjectsGrid = ({ projects }) => {
  const { donatModal, setDonatModal } = useContext(GlobalContext);
  const [activeId, setActiveId] = useState(null);
  const { lg } = useBreakpoint();

  const router = useHistory();
  const goDetails = (id, detay) => {
    router.push("/home/projects/" + id, { detay });
  };
  return (
    <div>
      <Modal
        onCancel={() => {
          setDonatModal(false);
          setActiveId(null);
        }}
        style={{ top: 20 }}
        centered={lg ? true : false}
        closable
        footer={null}
        visible={donatModal}
        title="Donation Form"
        width={lg ? "600px" : "100%"}
      >
        <DonationsForm activeId={activeId} isModal={true} />
      </Modal>
      <Row gutter={[16, 16]}>
        {projects.map((proje, index) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={8}
            lg={6}
            xl={6}
            style={{
              display: "inline-flex",
              alignSelf: "stretch",
            }}
          >
            <Card
              cover={
                <Image
                  preview={false}
                  alt="example"
                  src={
                    process.env.REACT_APP_FILES_BASEURL +
                    proje.project.images.find((a) => a.isPrimary).url
                  }
                />
              }
              className="kart"
              style={{
                textAlign: "center",
                border: "1px solid #ddd",
                boxShadow: "0 0 10px #ddd",
                transition: "all ease 200ms",
                paddingBottom: "45px",
              }}
              actions={[
                <Button
                  icon={<FileSearchOutlined />}
                  type="link"
                  onClick={() => goDetails(proje.project.id, true)}
                >
                  Details
                </Button>,
                <Button
                  icon={<HeartOutlined />}
                  type="primary"
                  onClick={() => {
                    setActiveId(proje.project.id);
                    setDonatModal(true);
                  }}
                >
                  Donate
                </Button>,
              ]}
            >
              <Meta title={proje.project.name} />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ProjectsGrid;
