import React, { useEffect } from "react";

const FacebookWidget = () => {
  useEffect(() => {
    // @ts-ignore
    if (window.FB) {
      // @ts-ignore
      window.FB.XFBML.parse();
    }
  }, []);

  return (
    <div
      className="fb-page"
      data-href="https://www.facebook.com/aurelieforg"
      data-tabs="timeline"
      data-width="500"
      data-height="555"
      data-small-header="false"
      data-adapt-container-width="true"
      data-hide-cover="false"
      data-show-facepile="true"
    >
      <blockquote
        cite="https://www.facebook.com/aurelieforg"
        className="fb-xfbml-parse-ignore"
      >
        <a href="https://www.facebook.com/aurelieforg">
          Australian Relief Organisation
        </a>
      </blockquote>
    </div>
  );
};

export default FacebookWidget;
