import { Col, Row, Image } from "antd";
import React from "react";

const SpecsBanner = () => {
  return (
    <div className="container">
      <Row gutter={[16, 16]} align="middle" justify="center">
        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
          <Image
            style={{
              border: "1px solid #ccc",
              boxShadow: "0 0 10px #eee",
              backgroundColor: "white",
            }}
            preview={false}
            src={require("../../assets/images/tax.png").default}
            width="100%"
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
          <Image
            style={{
              border: "1px solid #ccc",
              boxShadow: "0 0 10px #eee",
              backgroundColor: "white",
            }}
            preview={false}
            src={require("../../assets/images/cr.png").default}
            width="100%"
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
          <Image
            preview={false}
            style={{
              border: "1px solid #ccc",
              boxShadow: "0 0 10px #eee",
              backgroundColor: "white",
            }}
            src={require("../../assets/images/acnc.png").default}
            width="100%"
          />
        </Col>

        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
          <Image
            preview={false}
            style={{ border: "1px solid #ccc", boxShadow: "0 0 10px #eee" }}
            src={require("../../assets/images/au-gov.png").default}
            width="100%"
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
          <Image
            preview={false}
            style={{
              border: "1px solid #ccc",
              boxShadow: "0 0 10px #eee",
              backgroundColor: "white",
            }}
            src={require("../../assets/images/foodbank.png").default}
            width="100%"
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
          <Image
            preview={false}
            style={{
              border: "1px solid #ccc",
              boxShadow: "0 0 10px #eee",
              backgroundColor: "white",
            }}
            src={require("../../assets/images/unrwa.png").default}
            width="100%"
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
          <Image
            preview={false}
            style={{
              border: "1px solid #ccc",
              boxShadow: "0 0 10px #eee",
              backgroundColor: "white",
            }}
            src={require("../../assets/images/good360.png").default}
            width="100%"
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
          <Image
            preview={false}
            style={{
              border: "1px solid #ccc",
              boxShadow: "0 0 10px #eee",
              backgroundColor: "white",
            }}
            src={require("../../assets/images/fs.png").default}
            width="100%"
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
          <Image
            preview={false}
            style={{
              border: "1px solid #ccc",
              boxShadow: "0 0 10px #eee",
              backgroundColor: "white",
            }}
            src={require("../../assets/images/tt.png").default}
            width="100%"
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
          <Image
            preview={false}
            style={{
              border: "1px solid #ccc",
              boxShadow: "0 0 10px #eee",
              backgroundColor: "white",
            }}
            src={require("../../assets/images/ozharvest.png").default}
            width="100%"
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
          <Image
            style={{
              border: "1px solid #ccc",
              boxShadow: "0 0 10px #eee",
              backgroundColor: "white",
            }}
            preview={false}
            src={require("../../assets/images/sb.png").default}
            width="100%"
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
          <Image
            preview={false}
            style={{
              border: "1px solid #ccc",
              boxShadow: "0 0 10px #eee",
              backgroundColor: "white",
            }}
            src={require("../../assets/images/bt.png").default}
            width="100%"
          />
        </Col>
      </Row>
    </div>
  );
};

export default SpecsBanner;
