import { Card, Col, Row, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../services/AdminService";

const DashboardPage = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [reports, setReports] = useState(null);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getMainReports().then((data) => {
        setReports(data.data);
      });
    }
  }, [initialRender]);
  return (
    reports && (
      <div
        style={{
          backgroundColor: "#f5f5f5",
          padding: "15px",
          border: "1px solid #ddd",
        }}
      >
        <Row gutter={[6, 6]} wrap>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/projects")}
            >
              <Statistic
                title="
              Projects"
                value={reports.projects}
                valueStyle={{ fontWeight: "bold" }}
              />
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              style={{ borderColor: "#00baed", backgroundColor: "linen" }}
              hoverable
              onClick={() => router.push("/admin/donations")}
            >
              <Statistic
                title="Total Donation"
                value={"$" + reports.totalDonations.toFixed(2)}
                valueStyle={{ fontWeight: "bold" }}
                precision={2}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              style={{ borderColor: "#00baed", backgroundColor: "linen" }}
              hoverable
              onClick={() => router.push("/admin/donations")}
            >
              <Statistic
                title="Donation Fee"
                value={"$" + reports.mainMoney.toFixed(2)}
                valueStyle={{ fontWeight: "bold" }}
                precision={2}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              style={{ borderColor: "#00baed", backgroundColor: "linen" }}
              hoverable
              onClick={() => router.push("/admin/donations")}
            >
              <Statistic
                title="Admin Fee"
                value={"$" + reports.fee.toFixed(2)}
                valueStyle={{ fontWeight: "bold" }}
                precision={2}
              />
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/projects")}
            >
              <Statistic
                title="
              Card Payments"
                value={reports.card.toFixed(2)}
                valueStyle={{ fontWeight: "bold" }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/projects")}
            >
              <Statistic
                title="
              Paypal Payments"
                value={reports.paypal.toFixed(2)}
                valueStyle={{ fontWeight: "bold" }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/projects")}
            >
              <Statistic
                title="
              Bank Payments"
                value={reports.bank.toFixed(2)}
                valueStyle={{ fontWeight: "bold" }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/approval")}
            >
              <Statistic
                title="
              Awaiting Approval"
                value={reports.waiting}
                valueStyle={{ fontWeight: "bold" }}
              />
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              style={{ borderColor: "lightgreen", backgroundColor: "wheat" }}
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/donations")}
            >
              <Statistic
                title="Paid"
                value={"$" + (reports.onetime + reports.paidparts).toFixed(2)}
                valueStyle={{ color: "green", fontWeight: "bold" }}
                precision={2}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              style={{ borderColor: "#00baed", backgroundColor: "wheat" }}
              hoverable
              onClick={() => router.push("/admin/donations")}
            >
              <Statistic
                title="Donation Fee"
                value={
                  "$" +
                  (
                    reports.onetime +
                    reports.paidparts -
                    reports.oneTimeFee
                  ).toFixed(2)
                }
                valueStyle={{ fontWeight: "bold" }}
                precision={2}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              style={{ borderColor: "#00baed", backgroundColor: "wheat" }}
              hoverable
              onClick={() => router.push("/admin/donations")}
            >
              <Statistic
                title="Admin Fee"
                value={"$" + reports.oneTimeFee.toFixed(2)}
                valueStyle={{ fontWeight: "bold" }}
                precision={2}
              />
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/approval")}
            >
              <Statistic
                title="
             Cancelled"
                value={reports.cancelled}
                valueStyle={{ fontWeight: "bold" }}
              />
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/donations")}
            >
              <Statistic
                title="Waiting Part"
                value={"$" + reports.waitingparts.toFixed(2)}
                valueStyle={{ color: "#D26E26" }}
                precision={2}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/donations")}
            >
              <Statistic
                title="Installment donation"
                value={
                  "$" + (reports.waitingparts + reports.paidparts).toFixed(2)
                }
                precision={2}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/donations")}
            >
              <Statistic
                title=" Paid Installment
                "
                value={"$" + reports.paidparts.toFixed(2)}
                precision={2}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/donations")}
            >
              <Statistic
                title=" Payable Installment
                "
                value={"$" + reports.waitingparts.toFixed(2)}
                precision={2}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/donations")}
            >
              <Statistic
                title="Qty Donation"
                value={reports.donations}
                valueStyle={{ fontWeight: "bold" }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/qurbans")}
            >
              <Statistic title="Qurbans" value={reports.qurbans} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/quantities")}
            >
              <Statistic title="Donation Items" value={reports.donationItems} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/quantities")}
            >
              <Statistic title="Item Donations" value={reports.itemDonations} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/contacts")}
            >
              <Statistic title="Contacts" value={reports.contacts} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              className="stat-card"
              hoverable
              onClick={() => router.push("/admin/subscriptions")}
            >
              <Statistic
                title="
              Subscriptions"
                value={reports.subscriptions}
              />
            </Card>
          </Col>
        </Row>
      </div>
    )
  );
};

export default DashboardPage;
