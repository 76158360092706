import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Checkbox, Form, Input, Select } from "antd";
import React, { useContext, useCallback, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { AdminService } from "../../services/AdminService";
import { notification } from "antd";
import PhoneInput from "react-phone-number-input";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useHistory } from "react-router-dom";
const contries = require("../../resources/countries.json");

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { donations, setDonations, setCheckout } = useContext(GlobalContext);
  const { lg } = useBreakpoint();
  const [form] = Form.useForm();
  const [phone, setPhone] = useState(null);
  const router = useHistory();
  const [loading, setLoading] = useState(false);
  const handleSubmit = useCallback(
    async function (values) {
      //    event.preventDefault();
      setLoading(true);
      if (!stripe || !elements) {
        setLoading(false);
        return;
      }

      const cardElement = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        setLoading(false);
        return;
      }

      let response = await AdminService.newDonationStripe({
        name: values.fullname,
        phone: values.phone,
        email: values.email,
        address: values.address,
        subs: values.subs,
        suburb: values.suburb,
        postcode: values.postcode,
        state: values.state,
        country: values.country,
        payment: {
          payment_method_id: paymentMethod.id,
        },
        donations,
      });

      // 3D Secure
      while (response.status === 402) {
        const { paymentIntent, error } = await stripe.handleCardAction(
          response.data.token
        );
        if (error) {
          setLoading(false);
          // unable to pass 3Ds
          return;
        }

        response = await AdminService.newDonationStripe({
          payment: {
            payment_intent_id: paymentIntent.id,
          },
          donations,
        });
      }

      const ok = response.status === 200;
      if (ok) {
        notification.success({
          message: "Okay!",
          description: "Thank you for your donation!",
        });

        setDonations([]);
        setCheckout(0);
        form.resetFields();
        router.push("/home/completed");
      } else {
        setLoading(false);
      }
    },
    [stripe, elements, donations, setDonations, setCheckout, form, router]
  );

  const [states, setStates] = useState([
    {
      code: "ACT",
      name: "Australian Capital Territory",
      subdivision: null,
    },
    {
      code: "NSW",
      name: "New South Wales",
      subdivision: null,
    },
    {
      code: "NT",
      name: "Northern Territory",
      subdivision: null,
    },
    {
      code: "QLD",
      name: "Queensland",
      subdivision: null,
    },
    {
      code: "SA",
      name: "South Australia",
      subdivision: null,
    },
    {
      code: "TAS",
      name: "Tasmania",
      subdivision: null,
    },
    {
      code: "VIC",
      name: "Victoria",
      subdivision: null,
    },
    {
      code: "WA",
      name: "Western Australia",
      subdivision: null,
    },
  ]);
  function onChange(value) {
    const acountry = contries.filter((c) => {
      return c.code3 === value;
    });
    setStates(acountry[0].states);
    form.setFieldsValue({ state: null });
  }

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      style={{ maxWidth: "700px" }}
      initialValues={{ country: "AUS", subs: true }}
    >
      <Form.Item
        name="fullname"
        rules={[{ required: true, message: "Fullname is required" }]}
      >
        <Input placeholder={"Name of the donor"} />
      </Form.Item>
      <Form.Item
        name="phone"
        style={lg && { display: "inline-block", width: "calc(50% - 8px)" }}
        rules={[{ required: true, message: "Phone is required" }]}
      >
        <PhoneInput
          international
          defaultCountry="AU"
          value={phone}
          onChange={(a) => setPhone(a)}
          placeholder="Phone"
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Email is required" },
          { type: "email", message: "Invalid email address" },
        ]}
        style={
          lg && {
            display: "inline-block",
            width: "calc(50% - 0px)",
            margin: "0 0 0 8px",
          }
        }
      >
        <Input placeholder="Please Type Email" />
      </Form.Item>
      <Form.Item name="address">
        <Input placeholder="Please Type Address" />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          name="suburb"
          style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          rules={[{ required: true, message: "Address is required" }]}
        >
          <Input placeholder="Suburb" />
        </Form.Item>

        <Form.Item
          name="postcode"
          rules={[{ required: true, message: "Postcode is required" }]}
          style={{
            display: "inline-block",
            width: "calc(50% - 0px)",
            margin: "0 0 0 8px",
          }}
        >
          <Input placeholder="Postcode" />
        </Form.Item>
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          name="country"
          rules={[{ required: true, message: "Country is required" }]}
          style={{ display: "inline-block", width: "calc(50% - 8px)" }}
        >
          <Select
            showSearch
            placeholder="Select Country"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {contries.map((c) => (
              <Select.Option key={c.code3} value={c.code3}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="state"
          rules={[{ required: true, message: "State is required" }]}
          style={{
            display: "inline-block",
            width: "calc(50% - 0px)",
            margin: "0 0 0 8px",
          }}
        >
          <Select placeholder="Select State">
            {states.map((s) => (
              <Select.Option key={s.code} value={s.code}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Item>{" "}
      <Form.Item name="subs" valuePropName="checked">
        <Checkbox>Add my mail to newsletter list</Checkbox>
      </Form.Item>
      <h4 style={{ color: "#555" }}>
        Please Type Your Credit/Debit Card Details
      </h4>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "18px",
              fontFamily: "monospace",
              color: "#424770",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "red",
            },
          },
        }}
        className="st-form"
      />
      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        disabled={!stripe || !elements}
        block
        style={{ maxWidth: "500px" }}
        size="large"
      >
        Donate
      </Button>
    </Form>
  );
};
export default CheckoutForm;
