import { DeleteOutlined, FilePdfTwoTone } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Statistic,
  Table,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AdminService } from "../../services/AdminService";

const DonationDetails = () => {
  // @ts-ignore
  const { id } = useParams();
  const [initialRender, setInitialRender] = useState(true);
  const [donation, setDonation] = useState(null);
  const router = useHistory();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getDonationById(id).then((data) => {
        setDonation(data.data);
      });
    }
  }, [id, initialRender]);
  const getPeriod = () => {
    switch (donation.interval) {
      case 2:
        return "Day";
      case 3:
        return "Week";
      case 4:
        return "Month";
      case 5:
        return "Year";
      default:
        return "One off";
    }
  };

  const deleteQurban = (id) => {
    AdminService.deleteQurban(id).then((data) => {
      notification.success({ message: "Deleted!" });
      setInitialRender(true);
    });
  };
  const deletePart = (id) => {
    AdminService.deletePart(id).then((data) => {
      notification.success({ message: "Deleted!" });
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "On Behalf",
      dataIndex: "onbehalf",
    },

    {
      title: "Act.",
      dataIndex: "id",
      render: (id) => (
        <Tooltip title="Delete Qurban" placement="left">
          <Popconfirm
            title="Are you sure ? You cannot undo this action !"
            onConfirm={() => deleteQurban(id)}
            okText="Yes! Delete."
            cancelText="Cancel"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  const columns2 = [
    {
      title: "Date",
      dataIndex: "date",
      render: (date) => moment(date).format("DD-MM-YYYY ddd"),
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price) => <b>{"$" + price.toFixed(2)}</b>,
    },
    {
      title: "isPaid?",
      dataIndex: "isOk",
      render: (isOk) => (
        <Tag color={isOk ? "green" : "red"}>{isOk ? "Paid" : "Waiting"}</Tag>
      ),
    },

    {
      title: "Act.",
      dataIndex: "id",
      render: (id, item, index) =>
        !item.isOk ? (
          <Tooltip title="Delete" placement="left">
            <Popconfirm
              title="Are you sure ? You cannot undo this action !"
              onConfirm={() => deletePart(id)}
              okText="Yes! Delete."
              cancelText="Cancel"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        ) : (
          <FilePdfTwoTone
            twoToneColor="red"
            style={{ fontSize: "36px" }}
            onClick={() => downloadInvoice(donation.invoices[index])}
          />
        ),
    },
  ];

  const downloadInvoice = (id) => {
    AdminService.getInvoicePdf(id).then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <div className="stats-div" style={{ maxWidth: "1000px" }}>
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title={<span style={{ color: "#D26D24" }}>Donation Details</span>}
        extra={
          donation && (
            <Button
              onClick={() => downloadInvoice(donation.invoices[0])}
              type="primary"
              icon={<FilePdfTwoTone twoToneColor="red" />}
            >
              Download Invoice
            </Button>
          )
        }
      />

      {donation && (
        <>
          <Row wrap gutter={[6, 6]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              style={{
                display: "inline-flex",
                alignSelf: "stretch",
              }}
            >
              <Card className="stat-card" hoverable>
                <Statistic
                  title="Donation Price"
                  value={"$" + donation.total.toFixed(2)}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              style={{
                display: "inline-flex",
                alignSelf: "stretch",
              }}
            >
              <Card className="stat-card" hoverable>
                <Statistic title="Project" value={donation.projectName} />
              </Card>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              style={{
                display: "inline-flex",
                alignSelf: "stretch",
              }}
            >
              <Card className="stat-card" hoverable>
                <Statistic
                  title="Donation Date"
                  value={moment(donation.date).format("DD-MM-YYYY ddd")}
                />
              </Card>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              style={{
                display: "inline-flex",
                alignSelf: "stretch",
              }}
            >
              <Card className="stat-card" hoverable>
                <Statistic title="Donor" value={donation.fullname} />
              </Card>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              style={{
                display: "inline-flex",
                alignSelf: "stretch",
              }}
            >
              <Card className="stat-card" hoverable>
                <Statistic
                  title="Donor Email"
                  value={donation.email}
                  valueStyle={{ fontSize: "16px" }}
                />
              </Card>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              style={{
                display: "inline-flex",
                alignSelf: "stretch",
              }}
            >
              <Card className="stat-card" hoverable>
                <Statistic
                  title="Donor Phone"
                  value={donation.phone ? donation.phone : "-"}
                />
              </Card>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              style={{
                display: "inline-flex",
                alignSelf: "stretch",
              }}
            >
              <Card className="stat-card" hoverable>
                <Statistic title="Payment Method" value={donation.method} />
              </Card>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              style={{
                display: "inline-flex",
                alignSelf: "stretch",
              }}
            >
              <Card className="stat-card" hoverable>
                <Statistic
                  title="Stripe Payment Id"
                  value={donation.stripePaymentId}
                  valueStyle={{ fontSize: "14px" }}
                />
              </Card>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              style={{
                display: "inline-flex",
                alignSelf: "stretch",
              }}
            >
              <Card className="stat-card" hoverable>
                <Statistic
                  title="PayPal Payment Id"
                  value={donation.paypalPaymentId}
                  valueStyle={{ fontSize: "14px" }}
                />
              </Card>
            </Col>
            {donation.interval > 1 && (
              <>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={8}
                  style={{
                    display: "inline-flex",
                    alignSelf: "stretch",
                  }}
                >
                  <Card className="stat-card" hoverable>
                    <Statistic title="Donation Interval" value={getPeriod()} />
                  </Card>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={8}
                  style={{
                    display: "inline-flex",
                    alignSelf: "stretch",
                  }}
                >
                  <Card className="stat-card" hoverable>
                    <Statistic
                      title="Value"
                      value={"$" + donation.value.toFixed(2)}
                    />
                  </Card>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={8}
                  style={{
                    display: "inline-flex",
                    alignSelf: "stretch",
                  }}
                >
                  <Card className="stat-card" hoverable>
                    <Statistic
                      title="Times"
                      value={donation.total / donation.value}
                    />
                  </Card>
                </Col>
              </>
            )}
          </Row>
          <br />
          <br />
          <div
            style={{
              backgroundColor: "#f5f5f5",
              padding: "15px",
              border: "1px solid #ddd",
            }}
          >
            {donation.qurbans.length > 0 && (
              <>
                <Divider orientation="left">Donation Qurbans</Divider>
                <Table
                  bordered
                  size="small"
                  dataSource={donation.qurbans}
                  columns={columns}
                />
              </>
            )}
            {donation.parts.length > 0 && (
              <>
                <Divider orientation="left">Donation Parts</Divider>
                <Table
                  bordered
                  size="small"
                  dataSource={donation.parts}
                  columns={columns2}
                  pagination={false}
                />
              </>
            )}
            {donation.pType === 2 && (
              <div>
                <Divider orientation="left">Donation Items</Divider>{" "}
                <Row gutter={[16, 16]} wrap>
                  {donation.items.map((i) => (
                    <Col key={i.id}>
                      <Card className="stat-card" hoverable>
                        <Statistic
                          title={
                            i.itemName +
                            " - $" +
                            i.total / i.qty +
                            "x" +
                            i.qty +
                            " qty"
                          }
                          value={i.total}
                          prefix="$"
                          precision={2}
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DonationDetails;
