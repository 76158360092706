import { Image } from "antd";
import React from "react";
import ReactPlayer from "react-player";
import VolunteerForm from "../VolunteerForm";

const Volunteers = () => {
  return (
    <div>
      <section className="text-blueGray-700 ">
        <div className="container flex flex-col px-5 py-10 mx-auto lg:items-center">
          <div className="flex flex-col w-full mb-12 text-left lg:text-center">
            <h1 className="mb-6 text-2xl font-semibold tracking-tighter text-black sm:text-5xl title-font">
              Volunteers
            </h1>
            <p className="mx-auto text-base font-medium leading-relaxed text-blueGray-700 lg:w-2/3">
              Volunteers are an integral and indispensable part of ARO’s
              determination towards pursuing its goals.
            </p>
          </div>
        </div>
      </section>
      <section id="intro">
        <div className="container flex flex-col items-center justify-center px-10 pb-12 mx-auto rounded-lg lg:-mt-12 lg:px-40">
          <Image
            preview={false}
            className="object-cover object-center w-full shadow-xl rounded-xl"
            alt="hero"
            src={require("../../../assets/images/vol1.jpg").default}
          />
          <br />

          <p className="mx-auto text-base font-medium leading-relaxed text-blueGray-700 ">
            Volunteers are an integral and indispensable part of ARO’s
            determination towards pursuing its goals.
            <br />
            We thank all our volunteers who self sacrificing support our daily
            activities. Without their time, energy, skills and physical
            commitment, it would have been impossible to run our campaigns.
            <br />
            People of all ages, backgrounds and professions can volunteer with
            us, so please fill out the form below or email us on
            volunteer@aro.org.au
          </p>
          <br />
          <div className="container max-w-3xl mb-6">
            <Image.PreviewGroup>
              <Image
                className="p-1 imaj"
                width={200}
                src={require("../../../assets/images/vol1.jpg").default}
              />
              <Image
                className="p-1 imaj"
                width={200}
                src={require("../../../assets/images/vol2.jpg").default}
              />
              <Image
                className="p-1 imaj"
                width={200}
                src={require("../../../assets/images/vol3.jpg").default}
              />
              <Image
                className="p-1 imaj"
                width={200}
                src={require("../../../assets/images/vol4.jpg").default}
              />
              <Image
                className="p-1 imaj"
                width={200}
                src={require("../../../assets/images/vol5.jpg").default}
              />
              <Image
                className="p-1 imaj"
                width={200}
                src={require("../../../assets/images/vol6.jpg").default}
              />
            </Image.PreviewGroup>
          </div>
        </div>
        <div className="container mx-auto px-10 lg:px-40">
          <VolunteerForm /> <br />
          <br />
        </div>
      </section>
      <div className="container mb-20 max-w-4xl">
        <div className="player-wrapper  ">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=q4vOIhde8EU"
            className="react-player "
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Volunteers;
