import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Descriptions, Empty, Row, Space, Tabs } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import BankForm from "./BankForm";
import CheckoutForm from "./CheckoutForm";
import PaypalForm from "./PaypalForm";

const CheckOutPage = () => {
  const { donations, checkout, setDonations, setCheckout } =
    useContext(GlobalContext);

  const router = useHistory();
  const [activeMethod, setActiveMethod] = useState("card");
  const { TabPane } = Tabs;
  const { lg } = useBreakpoint();
  const stripePromise = useMemo(
    () =>
      loadStripe(
        "pk_live_51Gx2DID9ry7fmAL1ZmyK2clpQGz0inDILfU4X53QhG7Od0QiLOQTPrXT9hvwRx3jwPpzgVAC9Xxm27DNUWVPvtvD005me2eXYm"
      ),
    []
  );
  //const [datesModalOpen, setDatesModalOpen] = useState(false);
  // const [activeDonations, setActiveDonations] = useState({
  //   project: "",
  //   dparts: [],
  //   value: 0,
  // });

  // const goDates = (a) => {
  //   setActiveDonations(a);
  //   setDatesModalOpen(true);
  // };

  const methodChange = (m) => {
    setActiveMethod(m);
  };
  const onDelete = (ind, total) => {
    const temp = donations;
    temp.splice(ind, 1);
    setDonations(temp);
    setCheckout(checkout - total);
  };
  const getPeriod = (interval) => {
    switch (interval) {
      case 2:
        return "day";
      case 3:
        return "week";
      case 4:
        return "month";
      case 5:
        return "year";
      default:
        return "one off";
    }
  };
  const checkTimes = () => {
    if (donations.some((d) => d.times > 1)) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className="container" style={{ padding: "30px 0" }}>
      {/* <Modal
        visible={datesModalOpen}
        onCancel={() => setDatesModalOpen(false)}
        onOk={() => setDatesModalOpen(false)}
        title={activeDonations.project + "  donation schedule"}
      >
        <ol>
          {activeDonations.dparts.map((p) => (
            <li>
              {moment(p).format("DD-MM-YYYY ddd")} / ${activeDonations.value}
            </li>
          ))}
        </ol>
      </Modal> */}
      {donations.length === 0 && (
        <div style={{ textAlign: "center", marginTop: "40px" }}>
          <Empty description="Your donation box is empty" />
          <br />
          <Button type="primary" onClick={() => router.push("/home")}>
            Continue Donation
          </Button>
        </div>
      )}
      {donations.length !== 0 && (
        <div style={{ padding: "10px" }}>
          <Descriptions
            className={lg ? "fix-title" : ""}
            title="Your Donations "
            size="small"
            bordered
            layout={lg ? "horizontal" : "vertical"}
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            style={{ border: lg ? "1px solid #ddd" : "" }}
          >
            {donations.map((item, index) =>
              item.pType === 1 ? (
                <Descriptions.Item
                  key={index}
                  label={
                    item.qurbans.length +
                    " qty Qurban" +
                    item.qurbans.map((user) => {
                      return " / " + user.onbehalf + " (" + user.type + ") ";
                    })
                  }
                  style={!lg && { border: "1px solid #ffd591" }}
                  className="checkout-desk"
                >
                  <Row justify="space-between">
                    <span> ${item.total}</span>
                    <Button
                      onClick={() => onDelete(index, item.total)}
                      type="primary"
                      ghost
                      danger
                      size="small"
                      icon={<CloseOutlined />}
                    />
                  </Row>
                </Descriptions.Item>
              ) : item.pType === 4 ? (
                <Descriptions.Item
                  className="checkout-desk"
                  key={index}
                  label={
                    "Sadaqa / Zakat for " +
                    item.project +
                    " / " +
                    (item.interval === 1
                      ? "One Part"
                      : "$" +
                        item.value +
                        " per " +
                        getPeriod(item.interval) +
                        " x " +
                        item.times +
                        " times")
                  }
                >
                  <Row justify="space-between">
                    <span> ${item.total}</span>
                    <Button
                      onClick={() => onDelete(index, item.total)}
                      type="primary"
                      ghost
                      danger
                      size="small"
                      icon={<CloseOutlined />}
                    />
                  </Row>
                </Descriptions.Item>
              ) : (
                <Descriptions.Item
                  className="checkout-desk"
                  key={index}
                  label={"Sadaqa / Zakat for " + item.project}
                >
                  <Row justify="space-between">
                    <span> ${item.total}</span>
                    <Button
                      onClick={() => onDelete(index, item.total)}
                      type="primary"
                      ghost
                      danger
                      size="small"
                      icon={<CloseOutlined />}
                    />
                  </Row>
                </Descriptions.Item>
              )
            )}
            {activeMethod !== "bank" && (
              <Descriptions.Item
                label="Admin & Merchant Fee"
                style={!lg && { border: "1px solid #ffd591" }}
              >
                ${(checkout * 0.025).toFixed(2)}
              </Descriptions.Item>
            )}
            <Descriptions.Item
              style={!lg && { border: "1px solid #ffa940" }}
              label="Total Donation"
              labelStyle={{ color: "tomato" }}
            >
              <b>
                $
                {activeMethod !== "bank"
                  ? (checkout * 1.025).toFixed(2)
                  : checkout.toFixed(2)}
              </b>
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Space align="start">
            <ArrowDownOutlined />
            <p style={{ fontSize: "16px" }}>
              Please select your payment method or
            </p>
            <ArrowRightOutlined />
            <Button
              type="primary"
              ghost
              block
              onClick={() => router.push("/home")}
            >
              Continue Donation
            </Button>
          </Space>

          <br />
          {!checkTimes() && (
            <h4 style={{ color: "#D26D24" }}>
              Paypal and bank transfer are not listed because your donation has
              an installment option.
            </h4>
          )}
          <Tabs
            defaultActiveKey="bank"
            activeKey={activeMethod}
            onChange={methodChange}
          >
            <TabPane
              tab={
                <img
                  src={require("../../assets/images/card.png").default}
                  alt="card"
                  width="50px"
                />
              }
              key="card"
            >
              <Elements stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
              <br />

              <img
                src={require("../../assets/images/secure-stripe.png").default}
                alt="secure"
                width="100%"
                style={{ maxWidth: "500px" }}
              />
            </TabPane>
            {checkTimes() && (
              <>
                <TabPane
                  tab={
                    <img
                      src={require("../../assets/images/paypal.png").default}
                      alt="card"
                      width="80px"
                    />
                  }
                  key="paypal"
                >
                  <div style={{ maxWidth: "500px" }}>
                    <PaypalForm />
                    <br />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          require("../../assets/images/paypal-secure.png")
                            .default
                        }
                        alt="secure"
                        width="100%"
                        style={{ maxWidth: "200px" }}
                      />
                    </div>
                  </div>
                </TabPane>
              </>
            )}
            <TabPane
              tab={
                <img
                  src={require("../../assets/images/bank.png").default}
                  alt="card"
                  width="80px"
                />
              }
              key="bank"
            >
              <div>
                <BankForm />
              </div>
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default CheckOutPage;
