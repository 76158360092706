import { Row, Col, Space } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "../../services/AdminService";

const StatsBanner = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getStats().then((data) => {
        setStats(data.data);
      });
    }
  }, [initialRender]);
  return (
    <div
      style={{
        backgroundColor: "#ebebeb",
        border: "1px solid #ddd",
        marginTop: "40px",
      }}
    >
      <div className="container">
        <br />
        <h1
          className="font-merri fw-900"
          style={{
            fontSize: "30px",
            textAlign: "center",
            color: "#d46b08",
          }}
        >
          With Your Generous Donations We Have Achieved <br />
          The Following In Over 30 Countries
        </h1>
        {stats && (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <div className="stats-div h-full ">
                <Space direction="vertical">
                  <img
                    style={{ display: "unset" }}
                    width="48"
                    src={require("../../assets/images/water-well.png").default}
                    alt=""
                  />
                  <span
                    style={{
                      fontSize: "36px",
                      fontWeight: "bold",
                      color: "#444",
                    }}
                  >
                    {stats.well}
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#D26D24",
                    }}
                  >
                    Water Wells Opened
                  </span>
                </Space>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <div className="stats-div h-full">
                <Space direction="vertical">
                  <img
                    style={{ display: "unset" }}
                    width="48"
                    src={require("../../assets/images/homeless.png").default}
                    alt=""
                  />
                  <span
                    style={{
                      fontSize: "36px",
                      fontWeight: "bold",
                      color: "#444",
                    }}
                  >
                    {stats.homeless}
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#D26D24",
                    }}
                    className="font-merri"
                  >
                    Meals Weekly For Homeless
                  </span>
                </Space>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <div className="stats-div h-full">
                <Space direction="vertical">
                  <img
                    style={{ display: "unset" }}
                    width="48"
                    src={require("../../assets/images/cataract.png").default}
                    alt=""
                  />
                  <span
                    style={{
                      fontSize: "36px",
                      fontWeight: "bold",
                      color: "#444",
                    }}
                  >
                    {stats.cataract}
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#D26D24",
                    }}
                  >
                    Operations Completed
                  </span>
                </Space>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <div className="stats-div h-full">
                <Space direction="vertical">
                  <img
                    style={{ display: "unset" }}
                    width="48"
                    src={require("../../assets/images/orphanage.png").default}
                    alt=""
                  />
                  <span
                    style={{
                      fontSize: "36px",
                      fontWeight: "bold",
                      color: "#444",
                    }}
                  >
                    {stats.orphaneges}
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#D26D24",
                    }}
                  >
                    Orphanages Supported
                  </span>
                </Space>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <div className="stats-div h-full">
                <Space direction="vertical">
                  <img
                    style={{ display: "unset" }}
                    width="48"
                    src={require("../../assets/images/orphans.png").default}
                    alt=""
                  />
                  <span
                    style={{
                      fontSize: "36px",
                      fontWeight: "bold",
                      color: "#444",
                    }}
                  >
                    {stats.orphans}
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#D26D24",
                    }}
                  >
                    Orphans <br /> Sponsored
                  </span>
                </Space>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <div className="stats-div h-full">
                <Space direction="vertical">
                  <img
                    style={{ display: "unset" }}
                    width="48"
                    src={require("../../assets/images/goat.png").default}
                    alt=""
                  />
                  <span
                    style={{
                      fontSize: "36px",
                      fontWeight: "bold",
                      color: "#444",
                    }}
                  >
                    {stats.qurban}K
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#D26D24",
                    }}
                  >
                    Qurban Meat Packs Distributed
                  </span>
                </Space>
              </div>
            </Col>
          </Row>
        )}
        <br />
        <br />
      </div>
    </div>
  );
};

export default StatsBanner;
