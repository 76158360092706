import { Button, Image } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

const ThankYou = () => {
  const router = useHistory();
  return (
    <div style={{ textAlign: "center" }}>
      <Image
        preview={false}
        src={require("../../assets/images/thanks.png").default}
        style={{ maxWidth: "800px" }}
        alt="thanks"
      />

      <h3> Your invoice will be sent to your email address.</h3>
      <Button
        type="primary"
        size="large"
        onClick={() => router.push("/home/projects")}
      >
        Continue Donation
      </Button>
      <br />
      <br />
    </div>
  );
};

export default ThankYou;
