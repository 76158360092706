export const GlobalVariables = {
  formLayout: {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  },
  tailLayout: {
    wrapperCol: {
      offset: 6,
      span: 18,
    },
  },
  formLayoutLargeTitle: {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 12,
    },
  },
  qurbanOptions: [
    { label: "Wajib", value: "Wajib" },
    { label: "Aqueeqah", value: "Aqueeqah" },
    { label: "Nazr", value: "Nazr" },
    { label: "Nafila", value: "Nafila" },
  ],
  scopes: [
    { id: 1, name: "Overseas Project" },
    { id: 2, name: "Local Project" },
    { id: 3, name: "Seasonal Project" },
    //{ id: 4, name: "Seasonal Project" },
  ],
  projectTypes: [
    { id: 1, name: "Qurban Project" },
    { id: 2, name: "Quantity Items" },
    { id: 3, name: "Percentage Items" },
    { id: 4, name: "Free Donation" },
  ],
  compare: (a, b) => {
    var atitle = a || "";
    var btitle = b || "";
    var alfabe =
      "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
    if (atitle.length === 0 || btitle.length === 0) {
      return atitle.length - btitle.length;
    }
    for (var i = 0; i < atitle.length && i < btitle.length; i++) {
      var ai = alfabe.indexOf(atitle[i]);
      var bi = alfabe.indexOf(btitle[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  },
};
