import React from "react";
import { Link } from "react-router-dom";
import InstagramWidget from "../news/InstagramWidget";
import SpecsBanner from "../SpecsBanner";
import TopFooter from "../TopFooter";

const Awards = () => {
  return (
    <>
      <div className="container my-8 justify-center flex">
        <div className="max-w-3xl self-center flex-1">
          <p className="text-3xl font-bold text-yellow-600 text-center">
            Outstanding Charity Award
          </p>
          <p className="text-center text-md">
            Since 2014, Australian Relief Organisation has been running an
            “Orphan Sponsorship Program”.
          </p>
          <img
            className="shadow-light-600 shadow"
            src={require("../../../assets/images/award.png").default}
            alt="award"
            width="100%"
          />
          <p className="text-3xl font-bold text-yellow-600 text-center mt-8">
            Outstanding Achievement Award in <br />
            The Australian Charity Awards 2016
          </p>
          <p>
            We are delighted to inform you that Australian Relief Organisation
            has been recognised for Outstanding Achievement Award in The
            Australian Charity Awards 2016.
          </p>
          <p>
            The Australian Business Awards are annual, all-encompassing awards
            program honouring organisations that demonstrate the core values of
            business excellence, product excellence, sustainability and
            commercial success in their respective industries.
          </p>
          <p>
            Conducted annually the Awards honour the world’s industry leaders
            through the acknowledgment of innovative business processes, product
            development, enterprise, sustainability and overall business
            success.
          </p>
          <p>
            The Awards also recognise the important social and environmental
            contributions made by organisations through excellence of service,
            commitment to their customers, philanthropic ideals, ethical
            behaviour and environmental sustainability.
          </p>
          <p>
            The Australian Charity Award for Outstanding Achievement recognises
            charitable organisations that have achieved outstanding results
            through initiatives that have significantly benefited charitable
            causes.
          </p>
          <p>Further information can be reviewed on the following link:</p>
          <Link
            to={{ pathname: "https://australianbusinessawards.com.au" }}
            target="_blank"
          >
            https://australianbusinessawards.com.au/
          </Link>
          <br />
          <br />
          <p>
            We thank our sponsors, donors, directors, advisors, project
            coordinators, volunteers and community members who supported our
            charitable activities.
          </p>
          <p>
            Special thanks go to our local and international partners for making
            this achievement possible with their contribution on our 3rd year of
            operation.
          </p>
        </div>
      </div>
      <InstagramWidget />
      <SpecsBanner />
      <br />
      <TopFooter />
      <br />
    </>
  );
};

export default Awards;
