import {
  Card,
  Col,
  Divider,
  PageHeader,
  Row,
  Statistic,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { AdminService } from "../../services/AdminService";

const ProjectDonations = () => {
  // @ts-ignore
  const { id } = useParams();
  const [initialRender, setInitialRender] = useState(true);
  const [donations, setDonations] = useState([]);
  const [total, setTotal] = useState(0);
  const [waiting, setWaiting] = useState(0);
  const router = useHistory();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getProjectDonations(id).then((data) => {
        setDonations(data.data.donations);
        setTotal(data.data.total);
        setWaiting(data.data.waiting);
      });
    }
  }, [initialRender, id]);
  const columns = [
    {
      title: "D.Id",
      dataIndex: "id",
      render: (id) => <Link to={"/admin/donations/" + id}>{id}</Link>,
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (date) => moment(date).format("DD-MM-YY ddd"),
    },

    {
      title: "Donor",
      dataIndex: "fullname",
    },

    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Price",
      dataIndex: "total",
      render: (total) => <b>{"$" + total.toFixed(2)}</b>,
    },
    {
      title: "Method",
      dataIndex: "method",
    },
    {
      title: "Include",
      dataIndex: "pType",
      render: (pType, item) => (
        <div>
          {pType === 1 && (
            <Tag color="blue">Qurbans ({item.qurbans?.length})</Tag>
          )}
          {pType === 2 && (
            <Tag color="magenta">Items ({item.items?.length})</Tag>
          )}
          {pType === 4 && item.interval !== 1 && (
            <Tag color="green">D.Parts ({item.parts?.length})</Tag>
          )}
        </div>
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title={
          donations.length > 0
            ? donations[0].projectName + " donations"
            : "No donations to the project yet"
        }
      />
      <br />
      {donations && (
        <>
          <div
            style={{
              backgroundColor: "#f5f5f5",
              padding: "15px",
              border: "1px solid #ddd",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
                style={{
                  display: "inline-flex",
                  alignSelf: "stretch",
                }}
              >
                <Card className="stat-card">
                  <Statistic
                    title="Total Donation"
                    value={total}
                    prefix="$"
                    precision={2}
                  />
                </Card>
              </Col>{" "}
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
                style={{
                  display: "inline-flex",
                  alignSelf: "stretch",
                }}
              >
                <Card className="stat-card">
                  <Statistic
                    title="Paid"
                    value={total - waiting}
                    prefix="$"
                    precision={2}
                    valueStyle={{ color: "green", fontWeight: "bold" }}
                  />
                </Card>
              </Col>{" "}
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
                style={{
                  display: "inline-flex",
                  alignSelf: "stretch",
                }}
              >
                <Card className="stat-card">
                  <Statistic
                    title="Waiting"
                    value={waiting}
                    prefix="$"
                    precision={2}
                    valueStyle={{ color: "salmon" }}
                  />
                </Card>
              </Col>{" "}
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
                style={{
                  display: "inline-flex",
                  alignSelf: "stretch",
                }}
              >
                <Card className="stat-card">
                  <Statistic title="Donations Count" value={donations.length} />
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )}
      <br />{" "}
      <div
        style={{
          backgroundColor: "#f5f5f5",
          padding: "15px",
          border: "1px solid #ddd",
        }}
      >
        <Divider orientation="left">Donations List</Divider>
        <Table dataSource={donations} bordered columns={columns} />
      </div>
    </div>
  );
};

export default ProjectDonations;
