import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

import FacebookWidget from "./FacebookWidget";
import TwitterWidget from "./TwitterWidget";

const NewsBanner = () => {
  const router = useHistory();
  return (
    <div>
      <div
        className="relative  pt-16 pb-20 px-4 sm:px-6 lg:pt-10 lg:pb-16 lg:px-8"
        style={{ backgroundColor: "#ebebeb" }}
      >
        <div className="absolute inset-0">
          <div className="bg-gray-50 h-1/3 sm:h-2/3 border-t" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl  tracking-tight font-extrabold text-yellow-600 sm:text-4xl">
              Our Awards & Social Media Posts
            </h2>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  className="h-56 w-full object-cover"
                  src={require("../../../assets/images/award.png").default}
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900">
                    Outstanding Achievement Award In The Australian
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    We are delighted to inform you that Australian Relief
                    Organisation has been recognised for Outstanding Achievement
                    Award in The Australian Charity Awards 2016.
                  </p>
                </div>
                <div className="mt-6 flex items-center">
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => router.push("/home/awards")}
                  >
                    Read More
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    borderBottom: "1px solid #ddd",
                    padding: "8px 8px 8px 15px",
                    width: "100%",

                    backgroundColor: "white",
                    border: "1px solid #ddd",
                    borderRadius: "8px 8px 0 0 ",
                  }}
                >
                  <span style={{ fontSize: "18px", lineHeight: "30px" }}>
                    From Facebook
                  </span>
                </div>
                <FacebookWidget />
              </div>
            </div>
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <TwitterWidget />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsBanner;
