import { Button, Col, Row, Skeleton, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "../../services/AdminService";
import DonationsForm from "../donation/DonationsForm";
import FeaturedProject from "./FeaturedProject";
import JoinVolunteer from "./JoinVolunteer";
import OurPromise from "./OurPromise";
import ProjectsBar from "./ProjectsBar";
import ProjectsGrid from "./ProjectsGrid";
import SpecsBanner from "./SpecsBanner";
import StatsBanner from "./StatsBanner";
import TopFooter from "./TopFooter";
import NewsBanner from "./news/NewsBanner";

const Homepage = () => {
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("featured");
  const [initialRender, setInitialRender] = useState(true);
  const [proje, setProje] = useState(null);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getProjects().then((data) => {
        setProjects(data.data);
        console.log(data.data);
        if (data.data.length > 0) {
          setProje(
            data.data.filter((p) => p.project.isFeatured === true)[0].project
          );
        }
      });
    }
  }, [initialRender]);
  const tabChanged = (key) => {
    setActiveTab(key);
  };
  return (
    <div style={{ margin: "20px 0 0 0" }}>
      <div className="container">
        {/* <Image
          preview={false}
          className="w-full shadow-xl mb-6"
          alt="banner"
          src={require("../../assets/images/qurban66.jpg").default}
        /> */}
        {/* <div className="player-wrapper  ">
          <ReactPlayer
            playing={true}
            url="https://www.youtube.com/watch?v=3xlWs8eg0D4"
            className="react-player "
            width="100%"
            height="100%"
            controls={true}
            loop={true}
            muted={true}
          />
        </div> */}

        <Tabs defaultActiveKey="featured" onChange={tabChanged}>
          <TabPane
            style={{
              backgroundColor: "#ebebeb",
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "10px",
            }}
            tab={
              <Button
                ghost={activeTab === "featured" ? false : true}
                type="primary"
                style={{ borderWidth: "2px" }}
                size="large"
              >
                FEATURED PROJECT
              </Button>
            }
            key="featured"
          >
            <Row gutter={[46, 46]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                {proje && (
                  <div className="p-2">
                    <FeaturedProject proje={proje} />
                  </div>
                )}
                {!proje && (
                  <div style={{ textAlign: "center" }}>
                    <Skeleton.Image
                      style={{ width: "300px", height: "150px" }}
                    />
                    <br />
                    <br />
                    <Space>
                      <Skeleton.Button active={true} size="default" />
                      <Skeleton.Avatar active={true} size="default" />
                      <Skeleton.Button active={true} size="default" />
                    </Space>
                    <br />
                    <br />
                    <Skeleton.Input style={{ width: 300 }} active={true} />
                    <br />
                  </div>
                )}
              </Col>{" "}
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                {proje && (
                  <DonationsForm activeId={proje.id} name={proje.name} />
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane
            style={{
              backgroundColor: "#ebebeb",
              padding: "20px 10px",
              borderRadius: "10px",
              border: "1px solid #ddd",
            }}
            tab={
              <Button
                ghost={activeTab === "sadaqa" ? false : true}
                type="primary"
                style={{ borderWidth: "2px" }}
                size="large"
              >
                Choose your project
              </Button>
            }
            key="sadaqa"
          >
            <ProjectsGrid projects={projects} />
            {/* <DonationsForm /> */}
          </TabPane>
        </Tabs>
        {/* <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            {selectedProject !== null ? (
              <>
                <br />
                <br />
                <br />
                <FeaturedProject proje={selectedProject} />
                <ShareLinks shareUrl={window.location.href} />
              </>
            ) : (
              lg && (
                <div>
                  <br />
                  <br />

                  <h2
                    style={{
                      fontWeight: "bolder",
                      color: "#D26D24",
                      marginTop: "15px",
                    }}
                  >
                    Our Mission
                  </h2>

                  <h3 style={{ fontWeight: "bold" }}>
                    Our mission is to serve humanity.
                  </h3>
                  <h3>
                    In pursuing this mission, we engage in community support and
                    emergency relief in Australia. Our global focus on the other
                    hand, is on sustainable development, health, humanitarian
                    aid and emergency relief activities.
                  </h3>

                  <h2
                    style={{
                      fontWeight: "bolder",
                      color: "#D26D24",
                      marginTop: "15px",
                    }}
                  >
                    Our promise to you
                  </h2>

                  <h3>
                    Our work is impossible without your loyal support. That's
                    why ARO is committed to always doing the right thing right,
                    and keeping the people we work with around the world safe.
                    We promise to uphold the highest ethical standards in
                    everything we do.
                  </h3>
                </div>
              )
            )}

            <br />
          </Col> */}
      </div>
      <br />
      <StatsBanner />
      <br />
      <br />
      <ProjectsBar />
      <br />
      {/* <div className="container" style={{ maxWidth: "100%" }}>
        <h1
          className="font-merri fw-900"
          style={{
            fontSize: "30px",
            textAlign: "center",
            color: "#d46b08",
          }}
        >
          Watch some of our activities
        </h1>
        <div className="player-wrapper">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=jGSUcHv3s3k"
            className="react-player"
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
      </div> */}
      {/* <CharityAward /> */}
      {/* <JoinOur />
      <br /> */}
      {/* <OurPromise /> */}
      <OurPromise />
      <br />
      <JoinVolunteer />
      <br />
      <br />
      <NewsBanner />
      <div style={{ backgroundColor: "#ebebeb" }} className="pt-12">
        <SpecsBanner />
      </div>

      <div style={{ backgroundColor: "#ebebeb" }} className="py-10">
        <TopFooter />
      </div>
    </div>
  );
};

export default Homepage;
