import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  notification,
  Radio,
  Select,
  Upload,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { AdminService } from "../../services/AdminService";
const contries = require("../../resources/countries.json");

const BankForm = () => {
  const [form] = Form.useForm();
  const { lg } = useBreakpoint();
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [dekont, setDekont] = useState(true);
  const { donations, setDonations, setCheckout } = useContext(GlobalContext);

  const router = useHistory();
  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };
  const [states, setStates] = useState([
    {
      code: "ACT",
      name: "Australian Capital Territory",
      subdivision: null,
    },
    {
      code: "NSW",
      name: "New South Wales",
      subdivision: null,
    },
    {
      code: "NT",
      name: "Northern Territory",
      subdivision: null,
    },
    {
      code: "QLD",
      name: "Queensland",
      subdivision: null,
    },
    {
      code: "SA",
      name: "South Australia",
      subdivision: null,
    },
    {
      code: "TAS",
      name: "Tasmania",
      subdivision: null,
    },
    {
      code: "VIC",
      name: "Victoria",
      subdivision: null,
    },
    {
      code: "WA",
      name: "Western Australia",
      subdivision: null,
    },
  ]);
  function onChange(value) {
    const acountry = contries.filter((c) => {
      return c.code3 === value;
    });
    setStates(acountry[0].states);
    form.setFieldsValue({ state: null });
  }
  const handleSubmit = (values) => {
    if (dekont && fileList.length === 0) {
      notification.error({ message: "Please upload bank receipt" });
      return;
    }

    setLoading(true);

    AdminService.newDonationBankTransfer({
      name: values.fullname,
      phone: values.phone,
      email: values.email,
      address: values.address,
      subs: values.subs,
      suburb: values.suburb,
      postcode: values.postcode,
      state: values.state,
      country: values.country,
      receipt: fileList.length > 0 ? fileList[0].response.url : null,
      donations,
    })
      .then((data) => {
        notification.success({
          message: "Okay!",
          description: "Thank you for your donation!",
        });

        setDonations([]);
        setCheckout(0);
        form.resetFields();
        setLoading(false);
        router.push("/home/completed");
      })
      .catch(() => setLoading(false));
  };
  const beforeUpload = (file) => {
    const isPDF = file.type === "application/pdf";
    const isJPG = file.type === "image/jpeg";
    const isPNG = file.type === "image/png";
    if (!isPDF && !isJPG && !isPNG) {
      message.error("You can upload only image or pdf file");
      return Upload.LIST_IGNORE;
    }
    const isLt1M = file.size / 1024 / 1024 < 2;
    if (!isLt1M) {
      message.error("File size too long!");
      return Upload.LIST_IGNORE;
    }
    return (isPDF || isJPG || isPNG) && isLt1M;
  };
  return (
    <div>
      <p style={{ color: "#555", fontSize: "16px" }}>
        Admin & Merchant Fee <b>is not charged</b> in the bank transfer method.
        <br />
        Please complete form and upload the bank payment receipt for this
        donation.
        <br /> Our bank information is listed below.
      </p>
      <Form
        onFinish={handleSubmit}
        form={form}
        style={{ maxWidth: "700px" }}
        initialValues={{ country: "AUS", state: "NSW", subs: true }}
        layout="vertical"
      >
        <Form.Item
          name="fullname"
          rules={[{ required: true, message: "Fullname is required" }]}
        >
          <Input placeholder={"Name of the donor"} />
        </Form.Item>
        <Form.Item
          name="phone"
          style={lg && { display: "inline-block", width: "calc(50% - 8px)" }}
          rules={[{ required: true, message: "Phone is required" }]}
        >
          <PhoneInput
            international
            defaultCountry="AU"
            value={phone}
            onChange={(a) => setPhone(a)}
            placeholder="Phone"
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Invalid email address" },
          ]}
          style={
            lg && {
              display: "inline-block",
              width: "calc(50% - 0px)",
              margin: "0 0 0 8px",
            }
          }
        >
          <Input placeholder="Please Type Email" />
        </Form.Item>
        <Form.Item name="address">
          <Input placeholder="Please Type Address" />
        </Form.Item>
        <Form.Item
          name="suburb"
          style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          rules={[{ required: true, message: "Address is required" }]}
        >
          <Input placeholder="Suburb" />
        </Form.Item>

        <Form.Item
          name="postcode"
          rules={[{ required: true, message: "Postcode is required" }]}
          style={{
            display: "inline-block",
            width: "calc(50% - 0px)",
            margin: "0 0 0 8px",
          }}
        >
          <Input placeholder="Postcode" />
        </Form.Item>
        <Form.Item
          name="country"
          rules={[{ required: true, message: "Country is required" }]}
          style={{ display: "inline-block", width: "calc(50% - 8px)" }}
        >
          <Select
            showSearch
            placeholder="Select Country"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {contries.map((c) => (
              <Select.Option key={c.code3} value={c.code3}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="state"
          rules={[{ required: true, message: "State is required" }]}
          style={{
            display: "inline-block",
            width: "calc(50% - 0px)",
            margin: "0 0 0 8px",
          }}
        >
          <Select placeholder="Select State">
            {states.map((s) => (
              <Select.Option key={s.code} value={s.code}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Radio.Group onChange={() => setDekont(!dekont)} value={dekont}>
            <Radio value={true}>I have a bank receipt</Radio>
            <Radio value={false}>
              I will send the receipt later by e-mail.
            </Radio>
          </Radio.Group>
        </Form.Item>
        {dekont && (
          <Form.Item label="Bank receipt">
            <div className="clearfix">
              <Upload
                action={process.env.REACT_APP_API_BASEURL + "upload"}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }}
                beforeUpload={beforeUpload}
                listType="picture-card"
                fileList={fileList}
                onChange={handleChangeImage}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
            </div>
            <small>Accept only pdf or image</small>
          </Form.Item>
        )}
        <Form.Item name="subs" valuePropName="checked">
          <Checkbox>Add my mail to newsletter list</Checkbox>
        </Form.Item>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          block
          style={{ maxWidth: "500px" }}
          size="large"
        >
          Donate
        </Button>
      </Form>
    </div>
  );
};

export default BankForm;
