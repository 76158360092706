import {
  SaveOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  Divider,
  Form,
  Input,
  Space,
  Button,
  notification,
  Upload,
  Modal,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { GlobalVariables } from "../../../resources/GlobalVariables";
import { AdminService } from "../../../services/AdminService";
import ReactQuill from "react-quill";
import { useHistory, useLocation, useParams } from "react-router-dom";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const EditProject = () => {
  const [form] = Form.useForm();
  const [quillValue, setQuillValue] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const location = useLocation();
  const router = useHistory();
  // @ts-ignore
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  // @ts-ignore
  const { veri } = location.state || { veri: null };
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      if (veri === null) {
        router.push("/admin/projects");
      } else {
        setQuillValue(veri.details);
        veri.images.forEach((imaj) => {
          imaj.url = process.env.REACT_APP_FILES_BASEURL + imaj.url;
        });
        setFileList(veri.images.filter((i) => i.isPrimary === true));
        setFileList2(veri.images.filter((i) => i.isPrimary !== true));
        setItem(veri);
      }
    }
  }, [id, initialRender, router, veri]);

  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };
  const handleChange = ({ file, fileList }) => {
    setFileList2(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const onFinish = (values) => {
    if (fileList.length === 0) {
      notification.error({
        message: "Error!",
        description: "You must be save banner image",
      });
      return;
    }
    if (quillValue === "") {
      notification.error({
        message: "Error!",
        description: "Please type project details",
      });
      return;
    }

    const imajlar = [];
    if (fileList.length > 0) {
      if (fileList[0].id !== undefined) {
        fileList[0].url = fileList[0].url.split("\\").pop().split("/").pop();
        imajlar.push({
          id: fileList[0].id,
          url: fileList[0].url,
          isPrimary: true,
        });
      } else {
        imajlar.push({ url: fileList[0].response.url, isPrimary: true });
      }
    }
    if (fileList2.length > 0) {
      fileList2.forEach((i) => {
        if (i.id !== undefined) {
          i.url = i.url.split("\\").pop().split("/").pop();
          imajlar.push({ id: i.id, url: i.url, isPrimary: false });
        } else {
          imajlar.push({ url: i.response.url, isPrimary: false });
        }
      });
    }

    const req = {
      id: veri.id,
      description: values.description,
      name: values.name,
      details: quillValue,
      imajlar: imajlar,
      videolar: values.videos,
    };
    AdminService.editProject(req).then((data) => {
      notification.success({
        message: "Success",
        description: "Project added successfully",
      });
      router.push("/admin/projects");
    });
  };

  const quillChange = (e) => {
    setQuillValue(e);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const beforeUpload = (file) => {
    const isPDF = file.type === "application/pdf";
    const isJPG = file.type === "image/jpeg";
    const isPNG = file.type === "image/png";
    if (!isPDF && !isJPG && !isPNG) {
      message.error("You can upload only image or pdf file");
    }
    const isLt1M = file.size / 1024 / 1024 < 2;
    if (!isLt1M) {
      message.error("File size too long!");
    }
    return (isPDF || isJPG || isPNG) && isLt1M;
  };
  return (
    <div className="max-w-5xl">
      <Divider orientation="left">Edit project</Divider>
      {item && (
        <Form
          form={form}
          onFinish={onFinish}
          {...GlobalVariables.formLayout}
          className="form-border"
          initialValues={{ ...veri }}
        >
          <Form.Item
            name="name"
            label="Project Name"
            rules={[{ required: true, message: "Project name is required" }]}
          >
            <Input placeholder="Type new project name" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Project Description"
            rules={[
              { required: true, message: "Project description is required" },
            ]}
          >
            <Input placeholder="Type project short description" />
          </Form.Item>

          <Form.Item
            label="Project Details"
            rules={[{ required: true, message: "Details required" }]}
          >
            <ReactQuill
              modules={modules}
              theme="snow"
              onChange={(e) => quillChange(e)}
              placeholder="Type project long details"
              value={quillValue}
            />
          </Form.Item>
          <Form.Item label="Banner Image (required)">
            <div className="clearfix">
              <Upload
                action={process.env.REACT_APP_API_BASEURL + "upload"}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }}
                listType="picture-card"
                fileList={fileList}
                onChange={handleChangeImage}
                onPreview={handlePreview}
                beforeUpload={beforeUpload}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
            <small>Aspect Ratio must be 4:3</small>
          </Form.Item>
          <Form.Item label="Gallery Images (optional)">
            <>
              <Upload
                action={process.env.REACT_APP_API_BASEURL + "upload"}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }}
                listType="picture-card"
                fileList={fileList2}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </>
          </Form.Item>
          <Form.List name="videos">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "url"]}
                      fieldKey={[fieldKey, "url"]}
                      rules={[{ required: true, message: "Missing url" }]}
                    >
                      <Input
                        placeholder="Paste Video Link"
                        style={{ width: "300px" }}
                      />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Video
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item {...GlobalVariables.tailLayout}>
            <Space>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EditProject;
