import { CheckOutlined } from "@ant-design/icons";
import React from "react";
import SpecsBanner from "../SpecsBanner";

const SellForCharity = () => {
  return (
    <div className="container mx-auto">
      <div className="p-2 container mx-auto max-w-3xl mt-8">
        <h1 className="text-4xl font-extrabold text-yellow-600">
          Sell For Charity
        </h1>

        <p>
          If you have any new or used items in good condition you no longer
          need, send us couple of photos of the item/s. As part of our new
          Charity initiative, we can then:
        </p>
        <p>a) Give the item to those in need;</p>
        <p className="font-bold text-lg">OR</p>
        <p>
          b) If there is no need, we arrange for the online selling of the item
          and the proceeds of the sale will be used for charity.
        </p>
        <p className="font-bold text-lg">How it works:</p>
        <p>
          1. Send us some good quality photos, dimensions & brands of the item/s
          to be given.
        </p>
        <p>
          2. We place the items on our page, monitor online sales, payments,
          etc.
        </p>
        <p>
          3. You then hand them over to the buyer after it has been sold. Simple
          as that.
        </p>
        <p></p>
        <p className="font-bold text-lg">NB:</p>
        <p>
          <CheckOutlined /> ARO will not collect/pick up these items and store
          them.{" "}
        </p>
        <p>
          <CheckOutlined /> ARO reserves the right to reject any item that is
          deemed inappropriate.
        </p>
        <p>
          <CheckOutlined /> There is no freight/delivery involved.{" "}
        </p>
        <p>
          <CheckOutlined /> Items will be collected directly from you at a
          convenient time coordinated with the buyer.{" "}
        </p>
        <p>
          <CheckOutlined /> You can sell whatever you wish - anything that you
          feel is ready to go.
        </p>
        <br />
        <img
          src={require("../../../assets/images/sellforcharity.jpg").default}
          className="w-full border border-gray-400 shadow"
          alt="sell-for-charity"
        />
      </div>
      <br />
      <br />
      <SpecsBanner />
      <br />
      <br />
    </div>
  );
};

export default SellForCharity;
