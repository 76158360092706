import { Button, Image, Space } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import ShareLinks from "../share/ShareLinks";

const FeaturedProject = ({ proje }) => {
  const router = useHistory();
  return (
    <div>
      {/* <Divider style={{ marginTop: "32px" }}>
        <h1
          className="font-merri fw-900"
          style={{
            fontSize: "22px",
            textAlign: "center",
            color: "#d46b08",
          }}
        >
          Featured Project
        </h1>
      </Divider> */}

      {proje && (
        <Space direction="vertical" style={{ width: "100%" }}>
          {/* <h1>{proje.name}</h1> */}
          <Image
            preview={false}
            width="100%"
            className="rounded-sm shadow-sm"
            src={
              process.env.REACT_APP_FILES_BASEURL +
              proje.images.find((i) => i.isPrimary === true).url
            }
            alt=""
          />
          <p>{proje.description}</p>
          <Button
            type="default"
            onClick={() =>
              router.push("/home/projects/" + proje.id, { detay: true })
            }
          >
            Click For Details
          </Button>
          <ShareLinks
            shareUrl={window.location.href}
            title={
              "Please read this project of the Australian Relief Organisation. " +
              proje.name
            }
          />
        </Space>
      )}
    </div>
  );
};

export default FeaturedProject;
