import { Divider, Table } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "../../services/AdminService";

const Volunteers = () => {
  const [volunteers, setVolunteers] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getVolunteers().then((data) => {
        setVolunteers(data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstname",
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Age",
      dataIndex: "age",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Suburb",
      dataIndex: "suburb",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "Postcode",
      dataIndex: "postcode",
    },
    {
      title: "Occupation",
      dataIndex: "occupation",
    },
    {
      title: "Skills",
      dataIndex: "skills",
    },
    {
      title: "Days",
      dataIndex: "days",
    },
    {
      title: "Hear us from",
      dataIndex: "hear",
    },
    {
      title: "Message",
      dataIndex: "message",
    },
  ];
  return (
    <div>
      <Divider orientation="left">Volunteers</Divider>
      <Table
        scroll={{ x: 1600 }}
        dataSource={volunteers}
        columns={columns}
        size="small"
        bordered
        pagination={false}
        rowKey="id"
        loading={volunteers === null}
      />
    </div>
  );
};

export default Volunteers;
