import { FileSearchOutlined, HeartOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Image, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../services/AdminService";

const ProjectsBar = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [projects, setProjects] = useState([]);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getFavoriteProjects().then((data) => {
        setProjects(data.data);
      });
    }
  }, [initialRender]);
  const goDetails = (id, detay) => {
    router.push("/home/projects/" + id, { detay });
  };
  return (
    <div className="container">
      <Divider>
        <h1
          className="font-merri fw-900"
          style={{
            fontSize: "30px",
            textAlign: "center",
            color: "#d46b08",
          }}
        >
          Our Projects
        </h1>
      </Divider>

      <Row gutter={[16, 16]}>
        {projects.map((proje, index) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "inline-flex",
              alignSelf: "stretch",
            }}
          >
            <Card
              cover={
                <Image
                  preview={false}
                  alt="example"
                  src={
                    process.env.REACT_APP_FILES_BASEURL +
                    proje.project.images.find((a) => a.isPrimary).url
                  }
                />
              }
              className="kart"
              style={{
                textAlign: "center",
                border: "1px solid #ddd",
                boxShadow: "0 0 10px #eee",
                transition: "all ease 200ms",
                paddingBottom: "45px",
              }}
              actions={[
                <Button
                  icon={<FileSearchOutlined />}
                  type="link"
                  onClick={() => goDetails(proje.project.id, true)}
                >
                  Details
                </Button>,
                <Button
                  icon={<HeartOutlined />}
                  type="primary"
                  onClick={() => goDetails(proje.project.id, false)}
                >
                  Donate
                </Button>,
              ]}
            >
              <Meta
                title={proje.project.name}
                description={proje.project.description}
              />
            </Card>
          </Col>
        ))}
      </Row>
      <div style={{ textAlign: "center", padding: "15px" }}>
        <Button
          type="primary"
          size="large"
          onClick={() => router.push("/home/projects")}
        >
          See All Projects
        </Button>
      </div>
    </div>
  );
};

export default ProjectsBar;
