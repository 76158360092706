import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Space,
  Statistic,
  Switch,
  Table,
  Tooltip,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AdminService } from "../../services/AdminService";

const QurbanDashboard = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [qurbanProjects, setQurbanProjects] = useState([]);
  const [activeProject, setActiveProject] = useState(null);
  const [veri, setVeri] = useState(null);
  const [tablo, setTablo] = useState([]);
  const [veriMaster, setVeriMaster] = useState(null);
  const [deliverModal, setDeliverModal] = useState(false);
  const [deliverId, setDeliverId] = useState(null);
  const [deliverNote, setDeliverNote] = useState("");
  const [aktif, setAktif] = useState("All");
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getProjectByType(1).then((data) => {
        setQurbanProjects(data.data);
      });
    }
  }, [initialRender]);
  const projectSelected = (id) => {
    AdminService.getProjectById(id).then((data) => {
      setActiveProject(data.data);
      AdminService.getQurbanData(data.data.key).then((data) => {
        setVeri(data.data);
        setVeriMaster(data.data.data);
        setTablo(data.data.data);
      });
    });
  };
  const deleteQurban = (id) => {
    AdminService.deleteQurban(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Qurban has been deleted successfully",
      });
      AdminService.getQurbanData(activeProject.key).then((data) => {
        setVeri(data.data);
        setVeriMaster(data.data.data);
        setTablo(data.data.data);
      });
    });
  };
  const columns = [
    {
      title: "D.Id",
      dataIndex: "qurban",
      render: (qurban) => (
        <Link to={"/admin/donations/" + qurban.donationId}>
          {qurban.donationId}
        </Link>
      ),
    },
    {
      title: "D.Date",
      dataIndex: "qurban",
      render: (qurban) => moment(qurban.createdOn).format("DD-MM-YY dddd"),
    },
    {
      title: "Type",
      dataIndex: "qurban",
      render: (qurban) => qurban.type,
    },
    {
      title: "On Behalf",
      dataIndex: "qurban",
      render: (qurban) => qurban.onbehalf,
    },
    {
      title: "isDelivered",
      dataIndex: "qurban",
      render: (qurban) => (
        <Switch
          onChange={() => deliverChange(qurban.id)}
          defaultChecked={qurban.isDelivered}
        />
      ),
    },
    {
      title: "Note",
      dataIndex: "qurban",
      render: (qurban) => qurban.message,
    },
    {
      title: "Act.",
      dataIndex: "qurban",
      render: (qurban) => (
        <Tooltip title="Delete Qurban" placement="left">
          <Popconfirm
            title="Are you sure ? You cannot undo this action !"
            onConfirm={() => deleteQurban(qurban.id)}
            okText="Yes! Delete."
            cancelText="Cancel"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  const filterQurban = (tur) => {
    setAktif(tur);
    tur === "All"
      ? setTablo(veriMaster)
      : setTablo(veriMaster.filter((v) => v.qurban.type === tur));
  };
  const deliverChange = (id) => {
    setDeliverId(id);
    setDeliverModal(true);
  };
  const saveDeliver = () => {
    AdminService.putQurbansDeliver(deliverId, deliverNote).then((data) => {
      setDeliverModal(false);
      setDeliverNote("");
      AdminService.getQurbanData(activeProject.key).then((data) => {
        setVeri(data.data);
        setVeriMaster(data.data.data);
        setTablo(data.data.data);
      });
    });
  };
  return (
    <div>
      <Modal
        title="Please type note"
        visible={deliverModal}
        closable
        style={{ top: 20 }}
        onCancel={() => {
          setDeliverModal(false);
          setDeliverNote("");
        }}
        onOk={() => saveDeliver()}
      >
        <Input
          placeholder="Type note"
          autoFocus
          value={deliverNote}
          onPressEnter={() => saveDeliver()}
          onChange={(a) => setDeliverNote(a.target.value)}
        />
      </Modal>
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title="Qurbans list"
      />
      <br />
      <Space>
        <span>Choose Qurban Project</span>
        <Select
          style={{ minWidth: "250px" }}
          placeholder="Please choose project"
          onChange={projectSelected}
        >
          {qurbanProjects.map((q) => (
            <Select.Option key={q.key} value={q.project.id}>
              {q.project.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
      <br />
      <br />
      {veri && (
        <>
          <div
            style={{
              backgroundColor: "#f5f5f5",
              padding: "15px",
              border: "1px solid #ddd",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
                style={{
                  display: "inline-flex",
                  alignSelf: "stretch",
                }}
              >
                <Card
                  className="stat-card"
                  style={{ backgroundColor: "wheat", fontWeight: "bold" }}
                >
                  <Statistic
                    title={"Total Cost"}
                    value={veri.cost}
                    prefix="$"
                    precision={2}
                  />
                </Card>
              </Col>{" "}
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <Card
                  className="stat-card"
                  style={{ borderColor: "#00baed", backgroundColor: "wheat" }}
                >
                  <Statistic
                    title="Donation Fee"
                    value={"$" + (veri.cost - veri.fee).toFixed(2)}
                    valueStyle={{ fontWeight: "bold" }}
                    precision={2}
                  />
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <Card
                  className="stat-card"
                  style={{ borderColor: "#00baed", backgroundColor: "wheat" }}
                >
                  <Statistic
                    title="Admin Fee"
                    value={"$" + veri.fee.toFixed(2)}
                    valueStyle={{ fontWeight: "bold" }}
                    precision={2}
                  />
                </Card>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
                style={{
                  display: "inline-flex",
                  alignSelf: "stretch",
                }}
              >
                <Card
                  style={{
                    border: aktif === "All" ? "2px solid #D26D24" : "",
                  }}
                  className="stat-card"
                  hoverable
                  onClick={() => filterQurban("All")}
                >
                  <Statistic title="Total Qty" value={veri.qty} precision={0} />
                </Card>
              </Col>{" "}
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
                style={{
                  display: "inline-flex",
                  alignSelf: "stretch",
                }}
              >
                <Card
                  style={{
                    border: aktif === "Wajib" ? "2px solid #D26D24" : "",
                  }}
                  className="stat-card"
                  onClick={() => filterQurban("Wajib")}
                  hoverable
                >
                  <Statistic title="Wajib" value={veri.wajib} precision={0} />
                </Card>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
                style={{
                  display: "inline-flex",
                  alignSelf: "stretch",
                }}
              >
                <Card
                  style={{
                    border: aktif === "Aqueeqah" ? "2px solid #D26D24" : "",
                  }}
                  hoverable
                  className="stat-card"
                  onClick={() => filterQurban("Aqueeqah")}
                >
                  <Statistic
                    title="Aqueeqah"
                    value={veri.aqueeqah}
                    precision={0}
                  />
                </Card>
              </Col>{" "}
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
                style={{
                  display: "inline-flex",
                  alignSelf: "stretch",
                }}
              >
                <Card
                  style={{
                    border: aktif === "Nazr" ? "2px solid #D26D24" : "",
                  }}
                  hoverable
                  className="stat-card"
                  onClick={() => filterQurban("Nazr")}
                >
                  <Statistic title="Nazr" value={veri.nazr} precision={0} />
                </Card>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
                style={{
                  display: "inline-flex",
                  alignSelf: "stretch",
                }}
              >
                <Card
                  style={{
                    border: aktif === "Nafila" ? "2px solid #D26D24" : "",
                  }}
                  hoverable
                  className="stat-card"
                  onClick={() => filterQurban("Nafila")}
                >
                  <Statistic title="Nafila" value={veri.nafila} precision={0} />
                </Card>
              </Col>
            </Row>
          </div>
          <br />
          <div
            style={{
              backgroundColor: "#f5f5f5",
              padding: "15px",
              border: "1px solid #ddd",
            }}
          >
            <Divider orientation="left">Qurban Donations List</Divider>
            <Table
              bordered
              size="small"
              dataSource={tablo}
              columns={columns}
              scroll={{ x: 1000 }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default QurbanDashboard;
