import {
  BellOutlined,
  CheckCircleOutlined,
  ClusterOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  GiftOutlined,
  HeartOutlined,
  HistoryOutlined,
  HourglassOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  OrderedListOutlined,
  TeamOutlined,
  VerticalAlignMiddleOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Drawer, Layout, Menu, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import Approvals from "../components/admin/Approvals";
import CheckPayments from "../components/admin/CheckPayments";
import Contacts from "../components/admin/Contacts";
import DashboardPage from "../components/admin/DashboardPage";
import EditProject from "../components/admin/definitions/EditProject";
import Priority from "../components/admin/definitions/Priority";
import ProjectAdminDetails from "../components/admin/definitions/ProjectAdminDetails";
import Projects from "../components/admin/definitions/Projects";
import DonationDetails from "../components/admin/DonationDetails";
import DonationsDashboard from "../components/admin/DonationsDashboard";
import OldData from "../components/admin/OldData";
import ProjectDonations from "../components/admin/ProjectDonations";
import QtyDashboard from "../components/admin/QtyDashboard";
import QurbanDashboard from "../components/admin/QurbanDashboard";
import Stats from "../components/admin/Stats";
import Subscriptions from "../components/admin/Subscriptions";
import Volunteers from "../components/admin/Volunteers";
import MainMenu from "./MainMenu";

const AdminLayout = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { lg } = useBreakpoint();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSider, setIsSider] = useState(false);
  const router = useHistory();

  const logOut = () => {
    localStorage.clear();
    router.push("/home");
  };

  useEffect(() => {
    setIsSider(lg ? false : true);
  }, [lg]);
  return (
    <Layout>
      <Header
        className="header-class"
        style={{
          position: lg ? "fixed" : "unset",
          zIndex: 111,
          width: "100%",
          //  backgroundColor: "#000F1D",
          borderBottom: "1px solid #022A51",
          //   boxShadow: "1px 1px 10px #ddd",
        }}
      >
        <Row justify="space-between" align="middle">
          {lg ? undefined : (
            <Button
              icon={isSider ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              size="large"
              onClick={() => setIsSider(!isSider)}
            />
          )}
          <Col>
            <div className="logo" />
            {lg && (
              <div className="admin-menu">
                <MainMenu />
              </div>
            )}
          </Col>
          <Col>
            {lg ? (
              <Button onClick={logOut} type="primary" icon={<LogoutOutlined />}>
                Logout
              </Button>
            ) : (
              <Button
                icon={<MenuOutlined />}
                size="large"
                onClick={() => setIsCollapsed(true)}
              />
            )}
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {}}
          onCollapse={(collapsed, type) => {}}
          trigger={null}
          collapsible
          collapsed={isSider}
          theme="light"
          className={!lg ? "mobil-sider" : "desktop-sider"}
        >
          <Divider orientation="center">Admin Menu</Divider>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["dashboard"]}
            style={{ backgroundColor: "transparent" }}
          >
            <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
              <Link to="/admin/dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="Project" icon={<ClusterOutlined />}>
              <Link to="/admin/projects">Projects</Link>
            </Menu.Item>
            <Menu.Item key="priority" icon={<VerticalAlignMiddleOutlined />}>
              <Link to="/admin/priority">Project Priorities</Link>
            </Menu.Item>
            <Menu.Item key="donations" icon={<DollarCircleOutlined />}>
              <Link to="/admin/donations">Donations</Link>
            </Menu.Item>
            <Menu.Item key="approval" icon={<HourglassOutlined />}>
              <Link to="/admin/approval">Awaiting approval</Link>
            </Menu.Item>
            <Menu.Item key="qurbans" icon={<GiftOutlined />}>
              <Link to="/admin/qurbans">Qurbans</Link>
            </Menu.Item>
            <Menu.Item key="quantities" icon={<OrderedListOutlined />}>
              <Link to="/admin/quantities">Quantities</Link>
            </Menu.Item>
            <Menu.Item key="check" icon={<CheckCircleOutlined />}>
              <Link to="/admin/paymentcheck">Donor Check</Link>
            </Menu.Item>
            <Menu.Item key="stats" icon={<CheckCircleOutlined />}>
              <Link to="/admin/stats">Stats</Link>
            </Menu.Item>
            <Menu.Item key="volunteers" icon={<TeamOutlined />}>
              <Link to="/admin/volunteers">Volunteers</Link>
            </Menu.Item>
            <Menu.Item key="subs" icon={<BellOutlined />}>
              <Link to="/admin/subscriptions">Subscriptions</Link>
            </Menu.Item>
            <Menu.Item key="olddata" icon={<HistoryOutlined />}>
              <Link to="/admin/olddata">Old Data</Link>
            </Menu.Item>

            <Menu.Item key="contact" icon={<MessageOutlined />}>
              <Link to="/admin/contacts">Contacts</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content
          style={{
            marginTop: lg ? "74px" : undefined,
            padding: "10px",
            borderLeft: "1px solid #ddd",
          }}
        >
          <Drawer
            title={
              <img
                src={require("../assets/images/logo.png").default}
                width="150"
                alt="logo"
              />
            }
            visible={isCollapsed}
            placement="right"
            closable={true}
            onClose={() => setIsCollapsed(false)}
          >
            <MainMenu />
          </Drawer>
          <Switch>
            <Route path="/admin" exact>
              <Redirect to="/admin/dashboard" />
            </Route>
            <Route path="/admin/dashboard" component={DashboardPage} />

            <Route path="/admin/subscriptions" component={Subscriptions} />
            <Route
              path="/admin/projects/details"
              component={ProjectAdminDetails}
            />
            <Route path="/admin/projects/edit" component={EditProject} />
            <Route
              path="/admin/projects/:id/donations"
              component={ProjectDonations}
            />
            <Route path="/admin/projects" component={Projects} />
            <Route path="/admin/contacts" component={Contacts} />
            <Route path="/admin/qurbans" component={QurbanDashboard} />
            <Route path="/admin/quantities" component={QtyDashboard} />
            <Route path="/admin/donations/:id" component={DonationDetails} />
            <Route path="/admin/donations" component={DonationsDashboard} />
            <Route path="/admin/paymentcheck" component={CheckPayments} />
            <Route path="/admin/priority" component={Priority} />
            <Route path="/admin/approval" component={Approvals} />
            <Route path="/admin/stats" component={Stats} />
            <Route path="/admin/olddata" component={OldData} />
            <Route path="/admin/volunteers" component={Volunteers} />

            <Route>
              <Redirect to="/admin" />
            </Route>
          </Switch>
        </Content>
      </Layout>
      <Footer
        className="footer"
        style={{ textAlign: "center", fontSize: "13px" }}
      >
        © 2021 All Rights Reserved | Australian Relief Organisation | Developed
        by{" "}
        <a href="https://softmile.com" target="blank_">
          Softmile
        </a>{" "}
        with <HeartOutlined /> React
      </Footer>
    </Layout>
  );
};

export default AdminLayout;
