import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, PageHeader, Space, Table } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { GlobalVariables } from "../../resources/GlobalVariables";
import { AdminService } from "../../services/AdminService";

const OldData = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [oldData, setOldData] = useState(null);
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  const searchInput = useRef();
  const [kolonlar, setKolonlar] = useState({
    orderno: false,
    email: true,
    name: true,
    amount: true,
    timestamp: true,
    project: true,
    suburb: false,
    postcode: false,
    state: false,
    phone: true,
    method: false,
  });
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getOldData().then((data) => {
        setOldData(data.data);
      });
    }
  }, [initialRender]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ ...state, searchText: "" });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) => text,
  });

  const kolonChange = (a, b) => {
    setKolonlar({ ...kolonlar, [a]: !b });
  };

  const columns = [
    {
      title: "Order No",
      dataIndex: "orderno",
      sorter: (a, b) => a.id - b.id,
      className: kolonlar["orderno"] ? "kolonon" : "kolonoff",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => GlobalVariables.compare(a.email, b.email),
      className: kolonlar["email"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Donor",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => GlobalVariables.compare(a.name, b.name),
      className: kolonlar["name"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right",
      render: (amount) => (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {"$" + amount.toFixed(2)}
        </div>
      ),
      sorter: (a, b) => a.amount - b.amount,
      className: kolonlar["amount"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      render: (timestamp) => moment(timestamp).format("DD-MM-YY ddd"),
      className: kolonlar["timestamp"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Project",
      dataIndex: "project",
      ...getColumnSearchProps("project"),
      sorter: (a, b) => GlobalVariables.compare(a.project, b.project),
      className: kolonlar["project"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Suburb",
      dataIndex: "suburb",
      ...getColumnSearchProps("suburb"),
      sorter: (a, b) => GlobalVariables.compare(a.suburb, b.suburb),
      className: kolonlar["suburb"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Postcode",
      dataIndex: "postcode",
      ...getColumnSearchProps("postcode"),
      sorter: (a, b) => GlobalVariables.compare(a.postcode, b.postcode),
      className: kolonlar["postcode"] ? "kolonon" : "kolonoff",
    },
    {
      title: "State",
      dataIndex: "state",
      ...getColumnSearchProps("state"),
      sorter: (a, b) => GlobalVariables.compare(a.state, b.state),
      className: kolonlar["state"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
      sorter: (a, b) => GlobalVariables.compare(a.phone, b.phone),
      className: kolonlar["phone"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Method",
      dataIndex: "method",
      ...getColumnSearchProps("method"),
      sorter: (a, b) => GlobalVariables.compare(a.method, b.method),
      className: kolonlar["method"] ? "kolonon" : "kolonoff",
    },
  ];
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title="Old Donations Data"
        extra={
          <div className="max-w-lg flex flex-wrap justify-end">
            {Object.keys(kolonlar).map((key, index) => (
              <Checkbox
                key={key}
                defaultChecked={kolonlar[key]}
                onChange={() => kolonChange(key, kolonlar[key])}
              >
                {key[0].toUpperCase() + key.slice(1)}
              </Checkbox>
            ))}
          </div>
        }
      />
      <Table
        pagination={{ pageSize: 50 }}
        size="small"
        scroll={{ x: 1000 }}
        dataSource={oldData}
        bordered
        // @ts-ignore
        columns={columns}
      />
    </div>
  );
};

export default OldData;
