import {
  DollarOutlined,
  GlobalOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import { Col, Row, Space } from "antd";
import React from "react";

const TopFooter = () => {
  return (
    <div className="container">
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          style={{
            display: "inline-flex",
            alignSelf: "stretch",
          }}
        >
          <div
            className="kard"
            style={{
              textAlign: "center",
              border: "1px solid #ccc",
              boxShadow: "0 0 10px #ddd",
              padding: "20px",
              backgroundColor: "white",
              transition: "all ease 200ms",
            }}
          >
            <Space direction="vertical">
              <GlobalOutlined style={{ fontSize: "36px", color: "#d46b08" }} />
              <h3>Feeding families in over 30+ countries</h3>
              <p>
                Since 2012 we have been serving humanity in more than 30
                countries.
              </p>
            </Space>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          style={{
            display: "inline-flex",
            alignSelf: "stretch",
          }}
        >
          <div
            className="kard"
            style={{
              textAlign: "center",
              boxShadow: "0 0 10px #ddd",
              border: "1px solid #ccc",
              padding: "20px",
              backgroundColor: "white",
              transition: "all ease 200ms",
            }}
          >
            <Space direction="vertical">
              <SafetyOutlined style={{ fontSize: "36px", color: "#d46b08" }} />
              <h3>Secure Payments</h3>
              <p>
                Every donations is secure thanks to our excellent online safety
                standards.
              </p>
            </Space>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          style={{
            display: "inline-flex",
            alignSelf: "stretch",
          }}
        >
          <div
            className="kard"
            style={{
              transition: "all ease 200ms",
              textAlign: "center",
              boxShadow: "0 0 10px #ddd",
              border: "1px solid #ccc",
              padding: "20px",
              backgroundColor: "white",
            }}
          >
            <Space direction="vertical">
              <DollarOutlined style={{ fontSize: "36px", color: "#d46b08" }} />
              <h3>All donations are 100% tax-deductible</h3>
              <p>
                Every donation you make comes with a tax invoice to claim 100%.
              </p>
            </Space>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TopFooter;
