import { CloseOutlined } from "@ant-design/icons";
import { Button, Descriptions, Drawer, Row } from "antd";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";

const CoDrawer = () => {
  const router = useHistory();
  const {
    coDrawerOpen,
    setCoDrawerOpen,
    donations,
    setDonations,
    checkout,
    setCheckout,
  } = useContext(GlobalContext);
  const goCheckout = () => {
    router.push("/home/checkout");
    setCoDrawerOpen(false);
  };
  const getPeriod = (interval) => {
    switch (interval) {
      case 2:
        return "day";
      case 3:
        return "week";
      case 4:
        return "month";
      case 5:
        return "year";
      default:
        return "one off";
    }
  };
  const onClose = () => {
    setCoDrawerOpen(false);
  };
  const onDelete = (ind, total) => {
    const temp = donations;
    temp.splice(ind, 1);
    setDonations(temp);
    setCheckout(checkout - total);
  };
  return (
    <Drawer
      visible={coDrawerOpen}
      width="300"
      placement="right"
      closable={true}
      onClose={onClose}
    >
      {donations.length === 0 && (
        <div style={{ padding: "10px" }}>
          <img
            src={require("../../assets/images/logo.png").default}
            alt="logo"
            width="100"
          />
          <br />
          <br />
          <h3>Your donation box is empty</h3>
          <Button type="primary" block onClick={() => onClose()}>
            Continue Donation
          </Button>
        </div>
      )}
      {donations.length !== 0 && (
        <div style={{ padding: "10px" }}>
          <Descriptions
            title="Your Donations "
            size="small"
            bordered
            layout="vertical"
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          >
            {donations.map((item, index) =>
              item.pType === 1 ? (
                <Descriptions.Item
                  key={index}
                  label={
                    item.qurbans.length +
                    " qty Qurban" +
                    item.qurbans.map((user) => {
                      return " / " + user.onbehalf + " (" + user.type + ") ";
                    })
                  }
                  style={{ border: "1px solid #ffd591" }}
                  className="checkout-desk"
                >
                  <Row justify="space-between">
                    <span> ${item.total}</span>
                    <Button
                      onClick={() => onDelete(index, item.total)}
                      type="primary"
                      ghost
                      danger
                      size="small"
                      icon={<CloseOutlined />}
                    />
                  </Row>
                </Descriptions.Item>
              ) : item.pType === 4 ? (
                <Descriptions.Item
                  style={{ border: "1px solid #ffd591" }}
                  className="checkout-desk"
                  key={index}
                  label={
                    "Sadaqa / Zakat for " +
                    item.project +
                    " / " +
                    (item.interval === 1
                      ? "One Part"
                      : "$" +
                        item.value +
                        " per " +
                        getPeriod(item.interval) +
                        " x " +
                        item.times +
                        " times")
                  }
                >
                  <Row justify="space-between">
                    <span> ${item.total}</span>
                    <Button
                      onClick={() => onDelete(index, item.total)}
                      type="primary"
                      ghost
                      danger
                      size="small"
                      icon={<CloseOutlined />}
                    />
                  </Row>
                </Descriptions.Item>
              ) : (
                <Descriptions.Item
                  style={{ border: "1px solid #ffd591" }}
                  className="checkout-desk"
                  key={index}
                  label={"Sadaqa / Zakat for " + item.project}
                >
                  <Row justify="space-between">
                    <span> ${item.total}</span>
                    <Button
                      onClick={() => onDelete(index, item.total)}
                      type="primary"
                      ghost
                      danger
                      size="small"
                      icon={<CloseOutlined />}
                    />
                  </Row>
                </Descriptions.Item>
              )
            )}
            <Descriptions.Item
              style={{ border: "1px solid #ffa940" }}
              label="Total Donation"
              labelStyle={{ color: "tomato" }}
            >
              <b>${checkout}</b>
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Button type="primary" ghost block onClick={() => onClose()}>
            Continue Donation
          </Button>
          <br />
          <br />
          <Button type="primary" block onClick={() => goCheckout()}>
            Checkout
          </Button>
        </div>
      )}
    </Drawer>
  );
};

export default CoDrawer;
