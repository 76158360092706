import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification, Select, Space } from "antd";
import React from "react";
import { AdminService } from "../../services/AdminService";
const VolunteerForm = () => {
  const [form] = Form.useForm();
  const onFinish = (v) => {
    v.days = v.days.join(",");
    console.log(v);
    AdminService.newVolunteer(v).then((data) => {
      form.resetFields();
      notification.success({
        message: "Success!",
        description: "Your application has been submitted successfully.",
      });
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div className="p-4 bg-gray-100 border border-gray-200">
      <h1
        className="font-merri fw-900"
        style={{
          fontSize: "30px",
          textAlign: "center",
          color: "#d46b08",
        }}
      >
        Volunteer Now
      </h1>
      <Form form={form} onFinish={onFinish} onReset={onReset} layout="vertical">
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="firstname"
            rules={[{ required: true }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="lastname"
            rules={[{ required: true }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 0 0 16px",
            }}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="phone"
            rules={[{ required: true }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input placeholder="Phone" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 0 0 16px",
            }}
          >
            <Input placeholder="Email" />
          </Form.Item>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="age"
            rules={[{ required: true }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input placeholder="Age" />
          </Form.Item>
          <Form.Item
            name="gender"
            rules={[{ required: true }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 0 0 16px",
            }}
          >
            <Select placeholder="Gender" className="w-full">
              {["Male", "Female"].map((gender) => (
                <Select.Option key={gender} value={gender}>
                  {gender}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="address"
            rules={[{ required: true }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input placeholder="Address" />
          </Form.Item>
          <Form.Item
            name="suburb"
            rules={[{ required: true }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 0 0 16px",
            }}
          >
            <Input placeholder="Suburb" />
          </Form.Item>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="state"
            rules={[{ required: true }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input placeholder="State" />
          </Form.Item>
          <Form.Item
            name="postcode"
            rules={[{ required: true }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 0 0 16px",
            }}
          >
            <Input placeholder="Postcode" />
          </Form.Item>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="occupation"
            rules={[{ required: true }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input placeholder="Occupation" />
          </Form.Item>
          <Form.Item
            name="skills"
            rules={[{ required: true }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 0 0 16px",
            }}
          >
            <Input placeholder="Skills" />
          </Form.Item>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="hear"
            rules={[{ required: true }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input placeholder="Where did you hear about us?" />
          </Form.Item>
          <Form.Item
            name="days"
            rules={[{ required: true }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 0 0 16px",
            }}
          >
            <Select
              placeholder="Which days are you available"
              className="w-full"
              mode="multiple"
              allowClear
            >
              {[
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ].map((day) => (
                <Select.Option key={day} value={day}>
                  {day}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item name="message" rules={[{ required: true }]}>
          <Input.TextArea placeholder="Comments or questions" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button icon={<SaveOutlined />} type="primary" htmlType="submit">
              Save
            </Button>
            <Button icon={<ClearOutlined />} htmlType="reset">
              Clear
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VolunteerForm;
