import { Form, Input, Space, Button, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { AdminService } from "../../services/AdminService";

const ContactForm = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    AdminService.newContact(values).then((data) => {
      notification.success({
        message: "Success",
        description: "Your message has been sent",
      });
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div className="contact-form rounded-xl" style={{ width: "100%" }}>
      <Form form={form} onFinish={onFinish} onReset={onReset}>
        <Form.Item
          name="fullname"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input placeholder="Type your name" />
        </Form.Item>
        <Form.Item name="phone">
          <Input placeholder="Type your phone" inputMode="tel" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Invalid Email address" },
          ]}
        >
          <Input placeholder="Type your email" inputMode="email" />
        </Form.Item>
        <Form.Item
          name="subject"
          rules={[{ required: true, message: "Subject is required" }]}
        >
          <Input placeholder="Type your subject" />
        </Form.Item>
        <Form.Item
          name="message"
          rules={[{ required: true, message: "Message is required" }]}
        >
          <TextArea rows={4} placeholder="Type your message" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
            <Button type="primary" ghost htmlType="reset">
              Clear
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactForm;
