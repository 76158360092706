import {
  ClusterOutlined,
  GiftOutlined,
  HomeOutlined,
  IdcardOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";

const MainMenu = () => {
  const location = useLocation();
  const { lg } = useBreakpoint();
  const [actives, setActives] = useState([location.pathname.split("/").pop()]);
  const { setIsCollapsed } = useContext(GlobalContext);
  const router = useHistory();
  const { SubMenu } = Menu;
  const menuClick = (e) => {
    router.push("/home/" + e.key);
    setActives([e.key]);
    setIsCollapsed(false);
  };
  return (
    <Menu
      className="admin-menum"
      mode={lg ? "horizontal" : "inline"}
      defaultSelectedKeys={actives}
      onClick={menuClick}
      theme={lg ? "dark" : "light"}
    >
      <Menu.Item key="homepage" icon={<HomeOutlined />}>
        Home
      </Menu.Item>
      <Menu.Item key="projects" icon={<ClusterOutlined />}>
        Projects
      </Menu.Item>
      <SubMenu title="About" key="sub-1" icon={<IdcardOutlined />}>
        <Menu.Item key="about">About Us</Menu.Item>
        <Menu.Item key="awards">Awards</Menu.Item>
        <Menu.Item key="volunteers">Volunteers</Menu.Item>
      </SubMenu>
      {/* <Menu.Item key="donate">Donate</Menu.Item> */}
      <SubMenu title="Appeals" key="sub-2" icon={<ClusterOutlined />}>
        <Menu.Item key="clothing">Clothing Appeal</Menu.Item>
      </SubMenu>
      <Menu.Item key="contact" icon={<MailOutlined />}>
        Contact
      </Menu.Item>
      <Menu.Item key="mypage" icon={<GiftOutlined />}>
        My Donations
      </Menu.Item>
    </Menu>
  );
};
export default MainMenu;
