import { CheckOutlined } from "@ant-design/icons";
import { Image } from "antd";
import React from "react";
import ContactForm from "../ContactForm";
import SpecsBanner from "../SpecsBanner";
import TopFooter from "../TopFooter";

const ClothingAppeal = () => {
  return (
    <div>
      <div>
        <section className="text-gray-600 body-font">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
              <h1 className="title-font sm:text-2xl text-2xl mb-4 font-medium text-yellow-600">
                Attention all clothing manufacturers/retailers
              </h1>
              <p className="mb-8 leading-relaxed">
                Do you have any excess, ex-display or end of season stock that
                you need to get rid of to make way for new stock? Would you like
                to turn this stock into charity and lend a helping hand to those
                in need?
                <br />
                <br />
                If your answer is yes, then get in touch now to donate your
                clothing items to ARO, who will distribute these to the needy in
                Australia or overseas.
                <br />
                <br />
                Australian Relief Organisation is Requesting Donation of Your
                Excess Textile Stock for Our Clothing Appeal (Textile Recycling)
                Program
                <br />
                <br />
                Upon your approval, we can arrange pick up of any unwanted
                textile stock (male, female and children clothing, shoes and
                bags as well as manchester, towels, bed sheet, linen, curtains
                or similar homeware) from your stores and/or warehouse at any
                suitable time.
                <br />
                <br />
                With your company's donation, we hope to develop our program
                further, as these products will be used locally or
                internationally at your request to help the unfortunate families
                and other people who are in need of your support.
                <br />
                <br />
                To get in touch simply send us a message...
              </p>
            </div>
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
              <img
                className="object-cover object-center rounded imaj"
                alt="hero"
                src={require("../../../assets/images/clothing.jpg").default}
              />
              <h1 className="title-font sm:text-2xl text-2xl mt-3  font-medium text-yellow-600">
                Send us a Message
              </h1>
              <h1 className="title-font  mb-4 font-medium text-gray-900">
                Have a question? We’re here to help. Send us a message and we’ll
                be in touch.
              </h1>
              <ContactForm />
            </div>
          </div>
        </section>
      </div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-0 mx-auto flex flex-wrap">
          <div className="flex w-full mb-20 flex-wrap">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4">
              Our Clothing Bins Program
            </h1>
            <p className="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base">
              Australian Relief Organisation is involved in development, relief
              and advocacy activities for a better community.
            </p>
          </div>
          <Image.PreviewGroup>
            <div className="flex flex-wrap md:-m-2 -m-1 place-items-center">
              <Image
                className="imaj m-1"
                width={300}
                src={require("../../../assets/images/cl1.jpg").default}
              />
              <Image
                className="imaj m-1"
                width={300}
                src={require("../../../assets/images/cl2.jpg").default}
              />
              <Image
                className="imaj m-1"
                width={300}
                src={require("../../../assets/images/cl4.jpg").default}
              />
              <Image
                className="imaj m-1"
                width={300}
                src={require("../../../assets/images/cl5.jpg").default}
              />
              <Image
                className="imaj m-1"
                width={300}
                src={require("../../../assets/images/cl6.jpg").default}
              />
              <Image
                className="imaj m-1"
                width={300}
                src={require("../../../assets/images/cl7.jpg").default}
              />
            </div>
          </Image.PreviewGroup>
        </div>
      </section>
      <section className="text-blueGray-700 ">
        <div className="container flex flex-col items-center px-5 py-0 mx-auto">
          <div className="flex flex-col w-full mb-12 text-left ">
            <div className="w-full mx-auto lg:w-3/4">
              <h2 className="mx-auto mb-6 text-xl font-semibold mt-20 text-black title-font">
                Our program is important in an operating environment where 85%
                of used textiles are ending up in the landfill in Australia.
              </h2>
              <p className="mx-auto mb-4 text-base font-medium leading-relaxed text-blueGray-700 ">
                Program Details:
              </p>
              <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                  <CheckOutlined />
                </span>
                Dedicated Drivers As an experienced charitable textile recycling
                operator, we have a dedicated drivers team who monitor bins for
                timely maintenance.
              </p>
              <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                  <CheckOutlined />
                </span>
                Content Removal Performed several times a week during off peak
                hours. Frequency of visits can be adjusted according to volume.
              </p>
              <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                  <CheckOutlined />
                </span>
                Dedicated 1800 number Our bins display a dedicated 1800 phone
                number for extra pickup requests.
              </p>

              <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                  <CheckOutlined />
                </span>
                Comprehensive Site Cleaning Performed each time with content
                removal. Our site cleaning includes all rubbish removal even if
                they are not textile related.
              </p>
              <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                  <CheckOutlined />
                </span>
                Graffiti Removal and Repaint Graffiti is removed within two
                weeks after a vandalism is reported. Bins are repainted every
                two years.
              </p>
              <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                  <CheckOutlined />
                </span>
                Clothing Bin Dimension Our bins are 1000 mm wide, 1050 mm deep
                and 1750 mm high. This converts to a capacity of 1.83 m³.
              </p>
              <p className="flex items-center mb-2 font-semibold text-blueGray-700 ">
                <span className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full">
                  <CheckOutlined />
                </span>
                Serving a wide range of stakeholders Across Western Sydney, we
                have a number of clothing bins placed with councils, schools,
                child care centres and businesses.
              </p>
            </div>
          </div>
          <SpecsBanner />
          <br />
          <TopFooter />
          <br />
        </div>
      </section>
    </div>
  );
};

export default ClothingAppeal;
