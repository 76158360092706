import {
  DeleteOutlined,
  FileExcelOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Input,
  PageHeader,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { GlobalVariables } from "../../resources/GlobalVariables";
import { AdminService } from "../../services/AdminService";

const DonationsDashboard = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [donations, setDonations] = useState([]);
  const [donationsMaster, setDonationsMaster] = useState([]);
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  const [dateRange, setDateRange] = useState();
  const searchInput = useRef();
  const { RangePicker } = DatePicker;

  const [kolonlar, setKolonlar] = useState({
    date: true,
    projectName: true,
    total: false,
    price: true,
    fee: false,
    method: false,
    donor: true,
    email: true,
    phone: true,
    state: false,
    suburb: true,
    postcode: true,
    include: true,
  });
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getDonations().then((data) => {
        setDonations(data.data);
        setDonationsMaster(data.data);
      });
    }
  }, [initialRender]);

  const deleteDonation = (id) => {
    AdminService.deleteDonation(id).then((data) => {
      notification.success({
        message: "Success",
        description: "Donatiton deleted.",
      });
      setInitialRender(true);
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ ...state, searchText: "" });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      title: "D.Id",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          color={
            status === "Ok" ? "green" : status === "Waiting" ? "orange" : "red"
          }
        >
          {status}
        </Tag>
      ),

      sorter: (a, b) => GlobalVariables.compare(a.projectName, b.projectName),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (date) => moment(date).format("DD-MM-YY ddd HH:mm"),
      className: kolonlar["date"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Project",
      dataIndex: "projectName",
      ...getColumnSearchProps("projectName"),
      sorter: (a, b) => GlobalVariables.compare(a.projectName, b.projectName),
      className: kolonlar["projectName"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Donor",
      dataIndex: "fullname",
      ...getColumnSearchProps("fullname"),
      sorter: (a, b) => GlobalVariables.compare(a.fullname, b.fullname),
      className: kolonlar["donor"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => GlobalVariables.compare(a.email, b.email),
      className: kolonlar["email"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
      sorter: (a, b) =>
        GlobalVariables.compare(
          a.phone?.replace(/\D+/g, ""),
          b.phone?.replace(/\D+/g, "")
        ),
      className: kolonlar["phone"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Total",
      dataIndex: "total",
      align: "right",

      render: (total) => (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {"$" + total.toFixed(2)}
        </div>
      ),
      sorter: (a, b) => a.total - b.total,
      className: kolonlar["total"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Price",
      align: "right",
      dataIndex: "total",
      render: (a, b) => (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {`$${(b.total - b.fee).toFixed(2)}`}
        </div>
      ),
      className: kolonlar["price"] ? "kolonon" : "kolonoff",
    },
    {
      title: "A.Fee",
      dataIndex: "fee",
      align: "right",

      render: (fee) => (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {"$" + fee.toFixed(2)}
        </div>
      ),
      sorter: (a, b) => a.total - b.total,
      className: kolonlar["fee"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Method",
      dataIndex: "method",
      sorter: (a, b) => GlobalVariables.compare(a.method, b.method),
      className: kolonlar["method"] ? "kolonon" : "kolonoff",
    },

    {
      title: "Suburb",
      dataIndex: "suburb",
      ...getColumnSearchProps("suburb"),
      sorter: (a, b) => GlobalVariables.compare(a.method, b.method),
      className: kolonlar["suburb"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Postcode",
      dataIndex: "postcode",
      ...getColumnSearchProps("postcode"),
      sorter: (a, b) => GlobalVariables.compare(a.method, b.method),
      className: kolonlar["postcode"] ? "kolonon" : "kolonoff",
    },
    {
      title: "State",
      dataIndex: "state",
      ...getColumnSearchProps("state"),
      sorter: (a, b) => GlobalVariables.compare(a.method, b.method),
      className: kolonlar["state"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Include",
      dataIndex: "pType",
      render: (pType, item) => (
        <div>
          {pType === 1 && (
            <Tag color="blue">Qurbans ({item.qurbans?.length})</Tag>
          )}
          {pType === 2 && (
            <Tag color="magenta">Items ({item.items?.length})</Tag>
          )}
          {pType === 4 && item.interval !== 1 && (
            <Tag color="green">D.Parts ({item.parts?.length})</Tag>
          )}
        </div>
      ),
      className: kolonlar["include"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Actions",
      dataIndex: "pType",
      render: (pType, item) => (
        <Space>
          <Tooltip title="Delete Donation" placement="left">
            <Popconfirm
              title="Are you sure ? You cannot undo this action !"
              onConfirm={() => deleteDonation(item.id)}
              okText="Yes! Delete."
              cancelText="Cancel"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>{" "}
          <Tooltip title="Donation Details" placement="left">
            <Button
              onClick={() => router.push("/admin/donations/" + item.id)}
              type="primary"
              icon={<SearchOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const kolonChange = (a, b) => {
    setKolonlar({ ...kolonlar, [a]: !b });
  };
  const exportExcel = () => {
    AdminService.getDonationsExcel(dateRange).then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "donations.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };
  const exportMugi = () => {
    AdminService.getInvoicesExcel(dateRange).then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoices.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };
  const rangeChanged = (a) => {
    setDonations(
      donationsMaster.filter((d) => moment(d.date).isBetween(a[0], a[1]))
    );
    setDateRange(a);
  };
  return (
    <div className="donat-box">
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title="Donations"
        extra={
          <div className="max-w-full flex flex-wrap">
            {Object.keys(kolonlar).map((key, index) => (
              <Checkbox
                key={key}
                defaultChecked={kolonlar[key]}
                onChange={() => kolonChange(key, kolonlar[key])}
              >
                {key[0].toUpperCase() + key.slice(1)}
              </Checkbox>
            ))}
          </div>
        }
      />
      <Space>
        <RangePicker showTime onChange={rangeChanged} value={dateRange} />
        <Button
          onClick={() => {
            setDateRange(null);
            setDonations(donationsMaster);
          }}
        >
          Clear
        </Button>
      </Space>
      <Button
        style={{ float: "right" }}
        type="primary"
        icon={<FileExcelOutlined />}
        onClick={exportExcel}
      >
        Export Excel
      </Button>
      <Button
        style={{ float: "right" }}
        icon={<FileExcelOutlined />}
        onClick={exportMugi}
      >
        Export Invoices Excel
      </Button>

      <Divider orientation="left">Last Donations List</Divider>
      <Table
        size="small"
        scroll={{ x: 1000 }}
        pagination={{ pageSize: 50 }}
        dataSource={donations}
        bordered
        // @ts-ignore
        columns={columns}
      />
    </div>
  );
};

export default DonationsDashboard;
