import { PayPalButton } from "react-paypal-button-v2";
import { Checkbox, Form, Input, Select } from "antd";
import React, { useContext, useState, useRef, useEffect } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { AdminService } from "../../services/AdminService";
import { notification } from "antd";
import PhoneInput from "react-phone-number-input";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useHistory } from "react-router-dom";
const contries = require("../../resources/countries.json");

const PaypalForm = () => {
  const url = useRef();
  const options = {
    clientId:
      "Adh-cOHL1YNJsBR2hBc0h6cs10Oj0HPDtFndLQ63yWee1fRD3gF8h0-NGg-jMNqin8cytF2RZJCv8K4_",
    currency: "AUD",
    locale: "en_AU",
  };

  const { donations, setDonations, setCheckout } = useContext(GlobalContext);
  const { lg } = useBreakpoint();
  const [form2] = Form.useForm();
  const [phone, setPhone] = useState(null);
  const router = useHistory();
  const [values, setValues] = useState(null);

  const [click, setClick] = useState(0);

  useEffect(() => {
    if (window.paypalActions !== null) {
      form2
        .validateFields()
        .then((data) => {
          window.paypalActions.enable();
        })
        .catch((data) => {
          window.paypalActions.disable();
        });
    }
  }, [click, form2]);
  const [states, setStates] = useState([
    {
      code: "ACT",
      name: "Australian Capital Territory",
      subdivision: null,
    },
    {
      code: "NSW",
      name: "New South Wales",
      subdivision: null,
    },
    {
      code: "NT",
      name: "Northern Territory",
      subdivision: null,
    },
    {
      code: "QLD",
      name: "Queensland",
      subdivision: null,
    },
    {
      code: "SA",
      name: "South Australia",
      subdivision: null,
    },
    {
      code: "TAS",
      name: "Tasmania",
      subdivision: null,
    },
    {
      code: "VIC",
      name: "Victoria",
      subdivision: null,
    },
    {
      code: "WA",
      name: "Western Australia",
      subdivision: null,
    },
  ]);
  function onChange(value) {
    const acountry = contries.filter((c) => {
      return c.code3 === value;
    });
    setStates(acountry[0].states);
    form2.setFieldsValue({ state: null });
  }

  return (
    <Form
      onValuesChange={(changedValues, allValues) => {
        setValues(allValues);
        setClick(click + 1);
      }}
      form={form2}
      style={{ maxWidth: "700px" }}
      initialValues={{ country: "AUS", state: "NSW", subs: true, address: "" }}
    >
      <Form.Item
        name="fullname"
        rules={[{ required: true, message: "Fullname is required" }]}
      >
        <Input placeholder={"Name of the donor"} />
      </Form.Item>
      <Form.Item
        name="phone"
        style={lg && { display: "inline-block", width: "calc(50% - 8px)" }}
        rules={[{ required: true, message: "Phone is required" }]}
      >
        <PhoneInput
          international
          defaultCountry="AU"
          value={phone}
          onChange={(a) => setPhone(a)}
          placeholder="Phone"
        />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Email is required" },
          { type: "email", message: "Invalid email address" },
        ]}
        style={
          lg && {
            display: "inline-block",
            width: "calc(50% - 0px)",
            margin: "0 0 0 8px",
          }
        }
      >
        <Input placeholder="Please Type Email" />
      </Form.Item>

      <Form.Item name="address">
        <Input placeholder="Please Type Address" />
      </Form.Item>

      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          name="suburb"
          style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          rules={[{ required: true, message: "Address is required" }]}
        >
          <Input placeholder="Suburb" />
        </Form.Item>

        <Form.Item
          name="postcode"
          rules={[{ required: true, message: "Postcode is required" }]}
          style={{
            display: "inline-block",
            width: "calc(50% - 0px)",
            margin: "0 0 0 8px",
          }}
        >
          <Input placeholder="Postcode" />
        </Form.Item>
      </Form.Item>

      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          name="country"
          rules={[{ required: true, message: "Country is required" }]}
          style={{ display: "inline-block", width: "calc(50% - 8px)" }}
        >
          <Select
            showSearch
            placeholder="Select Country"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {contries.map((c) => (
              <Select.Option key={c.code3} value={c.code3}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="state"
          rules={[{ required: true, message: "State is required" }]}
          style={{
            display: "inline-block",
            width: "calc(50% - 0px)",
            margin: "0 0 0 8px",
          }}
        >
          <Select placeholder="Select State">
            {states.map((s) => (
              <Select.Option key={s.code} value={s.code}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Item>
      <Form.Item name="subs" valuePropName="checked">
        <Checkbox>Add my mail to newsletter list</Checkbox>
      </Form.Item>

      <PayPalButton
        style={{
          layout: "horizontal",
        }}
        options={options}
        onInit={(data, actions) => {
          actions.disable();
          window.paypalActions = actions;
        }}
        // onClick is called when the button is clicked
        onClick={() => {
          form2.validateFields();
        }}
        createOrder={async (data, actions) => {
          var response = await AdminService.newDonationPaypalCreate({
            donations,
          });

          url.current = response.data.callback_url;

          return response.data.orderID;
        }}
        onApprove={async (data, actions) => {
          await AdminService.newDonationPaypalConfirm(url.current, {
            name: values.fullname,
            phone: values.phone,
            email: values.email,
            address: values.address,
            subs: values.subs,
            suburb: values.suburb,
            postcode: values.postcode,
            state: values.state,
            country: values.country,

            donations,
          });

          notification.success({
            message: "Okay!",
            description: "Thank you for your donation!",
          });

          setDonations([]);
          setCheckout(0);
          form2.resetFields();
          router.push("/home/completed");
        }}
      />
    </Form>
  );
};
export default PaypalForm;
