import { HeartOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Row, Col, Input, Button, Form, notification } from "antd";
import React from "react";
import { AdminService } from "../../services/AdminService";
import Accounts from "./Accounts";
const AroFooter = () => {
  const [form] = Form.useForm();
  const onFinish = (value) => {
    AdminService.newSubscription(value).then((data) => {
      notification.success({
        message: "Success",
        description: "Subscription completed successfully",
      });
      form.resetFields();
    });
  };
  return (
    <div className="aro-footer">
      <div className="container">
        <Accounts />
        <br />
        <br />
        <Row align="middle" gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <img
              width="100%"
              src={require("../../assets/images/logo-large.png").default}
              alt="logo-large"
            />
            <p style={{ color: "#fff", fontSize: "18px" }}>
              ABN : 60 159 966 659
            </p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <div>
              <h2
                style={{
                  color: "#d46b08",
                  borderBottom: "1px solid rgba(159, 82, 27,.5)",
                  fontWeight: "bold",
                  lineHeight: "30px",
                }}
              >
                HEAD OFFICE
              </h2>
              <p style={{ color: "whitesmoke", fontSize: "17px" }}>
                27 Queen St, Auburn NSW 2144
              </p>
              <p
                style={{
                  color: "#fff",

                  fontSize: "24px",
                }}
              >
                <PhoneOutlined />
                <a
                  style={{
                    color: "#fff",
                  }}
                  href="tel://0280658469"
                >
                  {" "}
                  &nbsp; 02 8065 8469
                </a>
              </p>
              <p style={{ color: "#fff", fontSize: "18px" }}>
                <MailOutlined />
                <a
                  style={{
                    color: "#fff",
                  }}
                  href="mailto://info@aro.org.au"
                >
                  {" "}
                  &nbsp; info@aro.org.au
                </a>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <div style={{ color: "white" }}>
              <h2
                style={{
                  color: "#d46b08",
                  borderBottom: "1px solid rgba(159, 82, 27,.5)",
                  fontWeight: "bold",
                  lineHeight: "30px",
                }}
              >
                MELBOURNE BRANCH
              </h2>
              <p style={{ color: "whitesmoke", fontSize: "17px" }}>
                22-24 Lismore St, Dallas VIC 3047
              </p>
              <p
                style={{
                  color: "#fff",

                  fontSize: "24px",
                }}
              >
                <PhoneOutlined />
                <a
                  style={{
                    color: "#fff",
                  }}
                  href="tel://0393096168"
                >
                  {" "}
                  &nbsp; 03 9309 6168
                </a>
              </p>
              <p style={{ color: "#fff", fontSize: "18px" }}>
                <MailOutlined />
                <a
                  style={{
                    color: "#fff",
                  }}
                  href="mailto://info@aro.org.au"
                >
                  &nbsp; infovic@aro.org.au
                </a>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <div style={{ textAlign: "center" }}>
              <h3 style={{ color: "whitesmoke" }}>Be the first to know</h3>
              <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                  name="fullname"
                  style={{ textAlign: "center" }}
                  rules={[{ required: true, message: "Fullname required" }]}
                >
                  <Input
                    placeholder="Enter your fullname"
                    size="large"
                    style={{ maxWidth: "250px" }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  style={{ textAlign: "center" }}
                  rules={[
                    { required: true, message: "Email required" },
                    { type: "email" },
                  ]}
                >
                  <Input
                    placeholder="Enter your email address"
                    size="large"
                    inputMode="email"
                    style={{ maxWidth: "250px" }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large">
                    SUBSCRIBE
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      <div
        style={{
          backgroundColor: "rgba(41, 41, 41, 0.3)",
          borderTop: "1px solid black",
          margin: "30px 0 0 0",
          padding: "20px 0 10px 0",
          color: "#999999",
          textAlign: "center",
        }}
      >
        <p>
          © 2021 All Rights Reserved | Australian Relief Organisation |
          Developed by{" "}
          <a href="https://softmile.com.au" target="blank_">
            Softmile
          </a>{" "}
          with <HeartOutlined /> React
        </p>
      </div>
    </div>
  );
};

export default AroFooter;
