import { Row, Col, Space } from "antd";
import React from "react";

const Accounts = () => {
  return (
    <div style={{ margin: "15px 0" }}>
      <h1
        className="font-merri fw-900"
        style={{
          fontSize: "30px",
          textAlign: "center",
          color: "#eee",
        }}
      >
        OUR BANK ACCOUNTS
      </h1>
      <p style={{ textAlign: "center", fontSize: "16px", color: "#eee" }}>
        You can deposit your donations directly to our bank accounts below.
      </p>
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          style={{ display: "inline-flex", alignSelf: "stretch" }}
        >
          <div
            style={{
              textAlign: "center",
              border: "1px solid #151515",
              backgroundColor: "rgba(41, 41, 41, 0.7)",
              padding: "10px 20px",
              color: "#9CA3AE",
              width: "100%",
              boxShadow: "0 0 10px #202020",
            }}
          >
            <Space direction="vertical">
              <h2 style={{ color: "#eee" }}>
                DONATIONS FROM NSW, QLD, SA, WA, ACT, NT & OVERSEAS
              </h2>
              <div>
                <h2 style={{ color: "#D26D24" }}>ANZ Bank</h2>

                <h3 style={{ color: "#D26D24" }}>
                  <b>BSB 012 220</b>
                </h3>
                <h3 style={{ color: "#D26D24" }}>
                  <b>Acc No. 3818 66237</b>
                </h3>
              </div>
              <span className="block">Australian Relief Organisation Ltd</span>
              <span>Name Surname Project Name</span>
            </Space>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          style={{
            display: "inline-flex",
            alignSelf: "stretch",
          }}
        >
          <div
            style={{
              textAlign: "center",
              border: "1px solid #151515",
              backgroundColor: "rgba(41, 41, 41, 0.7)",
              padding: "10px 20px",
              color: "#9CA3AE",
              width: "100%",
              boxShadow: "0 0 10px #202020",
            }}
          >
            <Space direction="vertical">
              <h2 style={{ color: "#eee" }}>DONATIONS FROM VIC</h2>
              <h2>{""}</h2>
              <div>
                <h2 style={{ color: "#D26D24" }}>ANZ Bank</h2>

                <h3 style={{ color: "#D26D24" }}>
                  <b>BSB 012 220</b>
                </h3>
                <h3 style={{ color: "#D26D24" }}>
                  <b>Acc No. 1959 21917</b>
                </h3>
              </div>
              <span className="block">Australian Relief Organisation Ltd</span>
              <span>Name Surname Project Name</span>
            </Space>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          style={{ display: "inline-flex", alignSelf: "stretch" }}
        >
          <div
            style={{
              textAlign: "center",
              border: "1px solid #151515",
              backgroundColor: "rgba(41, 41, 41, 0.7)",
              padding: "20px",
              color: "#9CA3AE",
              width: "100%",
              boxShadow: "0 0 10px #202020",
            }}
          >
            <Space direction="vertical" align="center">
              <h2 style={{ color: "#eee" }}>Send Your Support via Bank</h2>
              <div style={{ color: "#9CA3AE" }}>
                <Space direction="vertical">
                  <span>
                    You can simply send your donations directly online with the
                    provided details.
                  </span>
                  <span> Important Note: </span>
                  <div>
                    <span>For your receipt, email </span>
                    <span style={{ color: "#D26D24", lineHeight: "24px" }}>
                      info@aro.org.au{" "}
                    </span>
                    <span> or </span>
                    <span style={{ color: "#D26D24" }}>
                      infovic@aro.org.au{" "}
                    </span>
                    if from Victoria
                  </div>
                </Space>
              </div>
            </Space>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Accounts;
