import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { AdminService } from "../../../services/AdminService";
import MyDonations from "./MyDonations";

const MyPage = () => {
  const [email, setEmail] = useState(null);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [donations, setDonations] = useState([]);
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    email === null
      ? AdminService.getLoginCode(values.email.toLowerCase())
          .then((data) => {
            setEmail(values.email.toLowerCase());
            setEmailDisabled(true);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          })
      : AdminService.checkPassCode(values)
          .then((data) => {
            setDonations(data.data);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
  };
  return (
    <div className="container">
      {donations.length === 0 && (
        <div className="bg-white py-16 sm:py-24">
          <div className="relative sm:py-16">
            <div aria-hidden="true" className="hidden sm:block">
              <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-100 rounded-r-3xl" />
              <svg
                className="absolute top-8 left-1/2 -ml-3"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
                />
              </svg>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-5xl lg:px-8">
              <div className="relative rounded-2xl px-6 py-6 bg-yellow-600 overflow-hidden shadow-xl sm:px-12 sm:py-10">
                <div
                  aria-hidden="true"
                  className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
                >
                  <svg
                    className="absolute inset-0 h-full w-full"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 1463 360"
                  >
                    <path
                      className="text-yellow-500 text-opacity-40"
                      fill="currentColor"
                      d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                    />
                    <path
                      className="text-yellow-700 text-opacity-40"
                      fill="currentColor"
                      d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                    />
                  </svg>
                </div>
                <div className="relative">
                  <div className="sm:text-center">
                    <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                      My Donations
                    </h2>
                    <p className="mt-6 mx-auto max-w-2xl text-base text-gray-100">
                      Please type your e-mail address. You can log in with the
                      one-time code that we will send to your e-mail address.
                    </p>
                  </div>
                  <div className=" flex justify-center">
                    <Form
                      style={{ width: "100%", maxWidth: "400px" }}
                      onFinish={onFinish}
                    >
                      <Form.Item name="email">
                        <Input
                          placeholder="Type your email"
                          size="large"
                          disabled={emailDisabled}
                        />
                      </Form.Item>
                      {!emailDisabled && (
                        <Form.Item>
                          <Button
                            loading={loading}
                            htmlType="submit"
                            size="large"
                            type="default"
                            block
                          >
                            Get Code
                          </Button>
                        </Form.Item>
                      )}
                      {emailDisabled && (
                        <>
                          <span className="text-white text-center">
                            A code has been sent to your e-mail. If the code is
                            not received, check the junk/spam folders.
                          </span>
                          <Form.Item name="code">
                            <Input
                              placeholder="Type your one pass code"
                              size="large"
                              className="block w-full"
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              loading={loading}
                              htmlType="submit"
                              size="large"
                              block
                            >
                              Get My Donations
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {donations.length > 0 && (
        <div>
          <br />
          <img
            src={require("../../../assets/images/thank-you.jpg").default}
            alt="thank-you"
            width="100%"
            style={{ maxWidth: "500px" }}
          />
          <MyDonations donations={donations} />
        </div>
      )}
    </div>
  );
};

export default MyPage;
