import {
  CheckCircleTwoTone,
  HourglassTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Descriptions,
  Divider,
  Input,
  Modal,
  PageHeader,
  Space,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import React, { useRef, useState } from "react";
import { GlobalVariables } from "../../../resources/GlobalVariables";

const MyDonations = ({ donations }) => {
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  const [planModal, setPlanModal] = useState(false);
  const [plan, setPlan] = useState([]);
  const [pName, setPName] = useState("");
  const [qurbanModal, setQurbanModal] = useState(false);
  const searchInput = useRef();

  const [kolonlar, setKolonlar] = useState({
    date: true,
    projectName: true,
    total: true,
    method: true,
    include: true,
    fullname: true,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ ...state, searchText: "" });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      render: (date) => moment(date).format("DD-MM-YY ddd HH:mm"),
      className: kolonlar["date"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Project",
      dataIndex: "projectName",
      ...getColumnSearchProps("projectName"),
      sorter: (a, b) => GlobalVariables.compare(a.projectName, b.projectName),
      className: kolonlar["projectName"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Donor",
      dataIndex: "fullname",
      sorter: (a, b) => GlobalVariables.compare(a.fullname, b.fullname),
      className: kolonlar["fullname"] ? "kolonon" : "kolonoff",
    },

    {
      title: "Price",
      dataIndex: "total",
      align: "right",

      render: (total) => (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {"$" + total.toFixed(2)}
        </div>
      ),
      sorter: (a, b) => a.total - b.total,
      className: kolonlar["total"] ? "kolonon" : "kolonoff",
    },
    {
      title: "Method",
      dataIndex: "method",
      sorter: (a, b) => GlobalVariables.compare(a.method, b.method),
      className: kolonlar["method"] ? "kolonon" : "kolonoff",
    },

    {
      title: <small>Click for Details</small>,
      dataIndex: "pType",
      render: (pType, item) => (
        <div>
          {pType === 1 && (
            <Tag
              style={{ cursor: "pointer" }}
              onClick={() => {
                setPlan(item.qurbans);
                setPName(item.projectName);
                setQurbanModal(true);
              }}
              color="blue"
            >
              Qurbans ({item.qurbans?.length})
            </Tag>
          )}
          {pType === 2 && (
            <Tag color="magenta">Items ({item.items?.length})</Tag>
          )}
          {pType === 4 && item.interval !== 1 && (
            <Tag
              style={{ cursor: "pointer" }}
              onClick={() => {
                setPlan(item.parts);
                setPName(item.projectName);
                setPlanModal(true);
              }}
              color="green"
            >
              D.Parts ({item.parts?.length})
            </Tag>
          )}
        </div>
      ),
      className: kolonlar["include"] ? "kolonon" : "kolonoff",
    },
    //   {
    //     title: "Actions",
    //     dataIndex: "pType",
    //     render: (pType, item) => (
    //       <Space>
    //         <Tooltip title="Delete Donation" placement="left">
    //           <Popconfirm
    //             title="Are you sure ? You cannot undo this action !"
    //             onConfirm={() => deleteDonation(item.id)}
    //             okText="Yes! Delete."
    //             cancelText="Cancel"
    //           >
    //             <Button type="primary" danger icon={<DeleteOutlined />} />
    //           </Popconfirm>
    //         </Tooltip>{" "}
    //         <Tooltip title="Donation Details" placement="left">
    //           <Button
    //             onClick={() => router.push("/admin/donations/" + item.id)}
    //             type="primary"
    //             icon={<SearchOutlined />}
    //           />
    //         </Tooltip>
    //       </Space>
    //     ),
    //   },
  ];

  const kolonChange = (a, b) => {
    setKolonlar({ ...kolonlar, [a]: !b });
  };

  return (
    <div className="donat-box mb-10">
      <Modal
        style={{ top: 20 }}
        visible={planModal}
        onCancel={() => setPlanModal(false)}
        onOk={() => setPlanModal(false)}
        width="350px"
      >
        <Descriptions
          title={pName + " donation plan"}
          bordered
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          size="small"
        >
          {plan.map((p) => (
            <Descriptions.Item
              key={p.id}
              label={moment(p.date).format("DD-MM-YYYY dddd")}
            >
              <span>
                ${p.price.toString() + "  "}
                {p.isOk ? (
                  <CheckCircleTwoTone twoToneColor="lime" />
                ) : (
                  <HourglassTwoTone twoToneColor="orange" />
                )}
              </span>
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Modal>
      <Modal
        style={{ top: 20 }}
        visible={qurbanModal}
        onCancel={() => setQurbanModal(false)}
        onOk={() => setQurbanModal(false)}
        width="350px"
      >
        <Descriptions
          title={pName + " donations"}
          bordered
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          size="small"
        >
          {plan.map((p) => (
            <Descriptions.Item key={p.id} label={p.onbehalf}>
              <span>{p.type}</span>
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Modal>
      <PageHeader
        className="site-page-header"
        title="Your Donations List"
        extra={
          <div className="max-w-full flex flex-wrap">
            {Object.keys(kolonlar).map((key, index) => (
              <Checkbox
                key={key}
                defaultChecked={kolonlar[key]}
                onChange={() => kolonChange(key, kolonlar[key])}
              >
                {key[0].toUpperCase() + key.slice(1)}
              </Checkbox>
            ))}
          </div>
        }
      />
      <Divider orientation="left">
        Your Donations List (since August 2021)
      </Divider>
      <Table
        scroll={{ x: 800 }}
        dataSource={donations}
        bordered
        // @ts-ignore
        columns={columns}
      />
    </div>
  );
};

export default MyDonations;
