import React from "react";
import ReactPlayer from "react-player";

const OurPromise = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8 ">
        <div className="bg-yellow-600 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4  ">
          <div
            className="flex justify-center pt-10 pb-6 px-6 sm:pt-8 sm:px-8 lg:py-16 lg:pr-0 xl:py-20 xl:px-20"
            style={{ paddingBottom: "30px", paddingTop: "30px" }}
          >
            <div className="lg:self-center">
              <h2 className="text-2xl font-extrabold text-white sm:text-3xl">
                <span className="block">OUR PROMISE TO YOU</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-yellow-50">
                Our work is impossible without your loyal support. That’s why
                ARO is committed to always doing the right thing right, and
                keeping the people we work with around the world safe. We
                promise to uphold the highest ethical standards in everything we
                do.
              </p>
            </div>
          </div>
          <div className="aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1 right-0 bottom-0">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=jGSUcHv3s3k"
                className="react-player"
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPromise;
