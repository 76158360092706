import { PageHeader, Tag, Image, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AdminService } from "../../services/AdminService";
import DonationsForm from "../donation/DonationsForm";
import ShareLinks from "../share/ShareLinks";

const ProjectDetails = () => {
  // @ts-ignore
  const { id } = useParams();
  const [initialRender, setInitialRender] = useState(true);
  const [veri, setVeri] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (initialRender) {
      window.scrollTo(0, 0);
      setInitialRender(false);
      AdminService.getProjectById(id).then((data) => {
        setVeri(data.data);
      });
    }
  }, [id, initialRender]);
  // @ts-ignore
  const { detay } = location.state || { detay: true };

  const router = useHistory();
  function createMarkup(gelenDetay) {
    return {
      __html: gelenDetay,
    };
  }
  return (
    <div className="container">
      {veri && (
        <>
          <PageHeader
            className="site-page-header"
            onBack={() => router.goBack()}
            title={veri.project.name}
            extra={[
              <Tag key="scope" color="#d46b08">
                {veri.project.scope.name}
              </Tag>,
            ]}
          />

          <Row gutter={[26, 26]}>
            {detay && (
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                {" "}
                <div>
                  <Image
                    preview={false}
                    className="imaj"
                    src={
                      process.env.REACT_APP_FILES_BASEURL +
                      veri.project.images[0].url
                    }
                    alt=""
                  />
                  <br />
                  <ShareLinks
                    shareUrl={window.location.href}
                    title={
                      "Please read this project of the Australian Relief Organisation. " +
                      veri.project.name
                    }
                  />
                  <br />
                  <div
                    dangerouslySetInnerHTML={createMarkup(veri.project.details)}
                  />
                  <br />
                  {veri.project.images.length > 1 && (
                    <>
                      <Divider orientation="left">Image Gallery</Divider>
                      <Image.PreviewGroup>
                        <Row align="middle" gutter={[10, 10]}>
                          {veri.project.images.map((imaj) => (
                            <Col>
                              <Image
                                className="gallery-image"
                                width={200}
                                src={
                                  process.env.REACT_APP_FILES_BASEURL + imaj.url
                                }
                              />
                            </Col>
                          ))}
                        </Row>
                      </Image.PreviewGroup>
                    </>
                  )}
                  {veri.project.videos.length > 0 && (
                    <>
                      <Divider orientation="left">Video Gallery</Divider>

                      <Row align="middle" gutter={[10, 10]}>
                        {veri.project.videos.map((video) => (
                          <Col
                            style={{
                              width: "100%",
                            }}
                          >
                            <div className="player-wrapper">
                              <ReactPlayer
                                url={video.url}
                                className="react-player"
                                width="100%"
                                height="100%"
                                controls={true}
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                  <br />
                </div>
              </Col>
            )}
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <DonationsForm activeId={veri.project.id} />
            </Col>
          </Row>

          <br />
          <br />
          <br />
        </>
      )}
    </div>
  );
};

export default ProjectDetails;
