import { Button, Form, InputNumber, notification } from "antd";
import React, { useEffect, useState } from "react";
import { GlobalVariables } from "../../resources/GlobalVariables";
import { AdminService } from "../../services/AdminService";

const Stats = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [items, setItems] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getStats().then((data) => {
        setItems(data.data);
      });
    }
  }, [initialRender]);

  const onFinish = (values) => {
    AdminService.putStats(values).then((data) => {
      notification.success({
        message: "Success!",
        description: "Values updated!",
      });
    });
  };
  return (
    <div>
      <h1 className="text-xl mb-6 mt-6 mx-auto text-center border-b text-yellow-600">
        Stats update form
      </h1>
      {items && (
        <Form
          form={form}
          {...GlobalVariables.formLayout}
          initialValues={{ ...items }}
          onFinish={onFinish}
        >
          <Form.Item label="Water Well" name="well">
            <InputNumber />
          </Form.Item>
          <Form.Item label="Homeless" name="homeless">
            <InputNumber />
          </Form.Item>
          <Form.Item label="Cataract" name="cataract">
            <InputNumber />
          </Form.Item>
          <Form.Item label="Orphaneges" name="orphaneges">
            <InputNumber />
          </Form.Item>
          <Form.Item label="Orphans" name="orphans">
            <InputNumber />
          </Form.Item>
          <Form.Item label="Qurbans (x1000)" name="qurban">
            <InputNumber />
          </Form.Item>
          <Form.Item {...GlobalVariables.tailLayout}>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default Stats;
